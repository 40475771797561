import React, { useState, useEffect } from "react";
import DashboardImg from "../../assets/dashboard.png";
import ProfileImg from "../../assets/profile.png";
import OrdersImg from "../../assets/my-orders.png";
import { Link } from "react-router-dom";
import "./Seller.css";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import GolgImg from "../../assets/gold.png";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import SortIcon from "@material-ui/icons/ArrowDownward";
import { useTranslation } from "react-i18next";
import "../styles.css";
import { SellingHeadersPart, SellingInformationOption } from "./sellingContent";

export default function Orders() {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [sellerComission, setSellerComission] = useState([]);
  const [sellerCount, setSellerCount] = useState([]);

  const [sellerError, setSellerError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // const [switchStatus, setSwitchStatus] = useState("1");
  let history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));

  const apiGetSellerTransitions = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Url}/sellers_details?seller_id=${user.user_id_info}`
      );
      const jsondata = await response.json();

      const { status, message } = jsondata || {};

      if (status) {
        setData(jsondata.data);
        setFilter(jsondata.data);
        setSellerComission(jsondata.comission);
        setSellerCount(jsondata.count);
        // setSellerCount(jsondata.gold_bar_price.gold_bar_price);
        localStorage.setItem(
          "gold_bar_price",
          jsondata.gold_bar_price.gold_bar_price
        );
      } else {
        setSellerError(message);
      }
      setIsLoading(false);
    } catch (error) {
      setSellerError("API request error:", JSON.stringify(error));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    apiGetSellerTransitions();
    const token = localStorage.getItem("token");
    if (!token) {
      history.push("/login");
    }
  }, []);

  //  useEffect(() => {
  //    const result = data.filter((item) => {
  //     return item.title.toLowerCase().match(search.toLowerCase());
  //    })
  //    //setFilter(result);
  //  }, [search]);

  //console.log(data.data);

  const handleWithdraw = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_Url}/fetchAddress`);
      const jsondata = await response.json();
      const msg = jsondata[0].address;
      Swal.fire("Successfully!", `You have withdrawn ${msg}`, "success");
    } catch (error) {
      Swal.fire("Error!", `You have withdrawn ${error}`, "error");
    }
  };
  const columns = [
    {
      name: `${t("Buyer ID")}`,
      selector: (row) => row.buyer_id,
    },
    {
      name: `${t("Partner ID")}`,
      selector: (row) => row.partner_id,
    },

    {
      name: `${t("Product Name")}`,
      selector: (row) => row.product_name,
    },
    {
      name: `${t("Product Price")}`,
      selector: (row) => {
        if (row.product_price <= 0) {
          return row.product_price;
        } else {
          return `${row.product_price} ₼`;
        }
      },
    },
    {
      name: `${t("Gold Bar Price")}`,
      selector: (row) => `${row.gold_bar_price} ₼`,
    },
    {
      name: `${t("Created At")}`,
      selector: (row) => row.created_at,
    },
    {
      name: `${t("Updated At")}`,
      selector: (row) => row.updated_at,
    },
    {
      name: `${t("Sales Mode")}`,
      selector: (row) => row.sales_mode,
    },
    {
      name: `${t("Buyer Cash Back")}`,
      selector: (row) => {
        const price = (row.product_price * row.buyers_commition) / 100;
        if (price <= 0) {
          return price;
        } else {
          return `${price} ₼`;
        }
      },
    },
    {
      name: `${t("Partner Cash Back")}`,
      selector: (row) => {
        const price = (row.product_price * row.partners_commition) / 100;
        if (price <= 0) {
          return price;
        } else {
          return `${price} ₼`;
        }
      },
    },
    {
      name: `${t("status")}`,
      selector: (row) => {
        const status = row.active_status || "Pending";

        const styleStatus =
          row.active_status == "1"
            ? "tgl-on"
            : row.active_status == "2"
            ? "tgl-def"
            : row.active_status == "3"
            ? "tgl-off"
            : "";

        const styleStatusText =
          row.active_status == "1"
            ? "Success"
            : row.active_status == "2"
            ? "Pending"
            : row.active_status == "3"
            ? "Cancel"
            : "";

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div className="wrapper">
              <input
                type="range"
                name="points"
                onChange={(event) => {
                  filterme(row, event.target.value);
                }}
                min="1"
                step="1"
                id="custom-toggle"
                className={styleStatus || ""}
                max="3"
                value={status}
                // {status}
              />
            </div>
            <p>{styleStatusText}</p>
          </div>
        );
      },
    },
  ];

  const apiGetStatusChange = async (rowId, statusChangeValue) => {
    try {
      const url = `${process.env.REACT_APP_Url}/sellerStatusUpdate`;

      const urlRequest = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: `${statusChangeValue},${rowId}`,
        }),
      });

      const response = await urlRequest.json();

      if (response?.status) {
        Swal.fire("Successfully!", response.message, "success", {
          buttons: false,
          timer: 2000,
        });
        setData((prev) => {
          return prev.map((item) => {
            if (item.id === rowId) {
              return {
                ...item,
                active_status: statusChangeValue,
              };
            } else {
              return item;
            }
          });
        });
      }
    } catch (error) {
      console.error("API request error:", error);
      Swal.fire("Error", error, "error");
    }
  };

  const filterme = async (row, value) => {
    if (row.active_status == "2" && value == "1") {
      // nothing happen it will remain in "2"
    } else if (row.active_status == "3" && value == "1") {
      alert("Do you want to go directly cancel to success you can't go?");
      apiGetStatusChange(row.id, "2");
    } else if (row.active_status == "1") {
      alert("do you want to change success to pending can't do it?");
      apiGetStatusChange(row.id, "3");
    } else {
      apiGetStatusChange(row.id, value);
    }
  };

  const tableData = {
    columns,
    data,
  };

  const newData =
    data?.length > 0
      ? data?.map((items) => {
          const dataTable = {
            buyers_name: items.buyers_name,
            partners_name: items.partners_name,
            seller_name: items.seller_name,
            product_name: items.product_name,
            product_price: items.product_price,
            gold_bar_price: items.gold_bar_price,
            sales_mode: items.sales_mode,
            sales_source: items.sales_source,
            buyers_commition: ` ${
              (items.product_price * items.buyers_commition) / 100
            }`,

            partners_commition: ` ${
              (items.product_price * items.partners_commition) / 100
            }`,
            created_at: items.created_at,
            updated_at: items.updated_at,
          };

          return dataTable;
        })
      : null;

  let sellerData = null;

  if (isLoading) {
    sellerData = (
      <div className="bordered-shadow-box">
        <div className="bordered-shadow-box-overflow-hidden">
          <div className="seller-profile-sidebar">
            <div className="seller-profile-sidebar-collapsed-menu-area first">
              <div className="seller-profile-sidebar-menu collapse show">
                <ul>
                  <li>
                    {" "}
                    <span style={{ marginTop: "10px", marginLeft: "10px" }}>
                      Loading....
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!isLoading && sellerError) {
    sellerData = (
      <div className="bordered-shadow-box">
        <div className="bordered-shadow-box-overflow-hidden">
          <div className="seller-profile-sidebar">
            <div className="seller-profile-sidebar-collapsed-menu-area first">
              <div className="seller-profile-sidebar-menu collapse show">
                <ul>
                  <li>
                    <span style={{ marginTop: "10px", marginLeft: "10px" }}>
                      {sellerError} :)
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!isLoading && data?.length === 0) {
    sellerData = <h1>No Data Avaiable</h1>;
  } else if (!isLoading && data?.length > 0) {
    sellerData = (
      <div>
        <div>
          {/* -------  Transaction List ------ */}
          <div className="row seller-dashboard-col-equal">
            <div className="col-lg-12">
              <div className="seller-profile-panel">
                <div className="seller-profile-panel-header transaction">
                  {t("Transaction History")}
                </div>
                <div className="seller-profile-panel-body">
                  <div className="table-responsive">
                    <DataTableExtensions {...tableData}>
                      <DataTable
                        columns={columns}
                        data={newData}
                        noHeader
                        defaultSortField="id"
                        sortIcon={<SortIcon />}
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        dense
                      />
                    </DataTableExtensions>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  -------------- */}
        </div>
      </div>
    );
  }

  return (
    <>
      <div id="layout">
        <div className="main">
          <div>
            <div className="header-fixed-content-layout">
              <section className="seller-profile-area common-padding white-shaded-bg">
                <div className="container">
                  <div className="row">
                    {/* ------  Sidebar ------- */}
                    {/* seller-profile-sidebar-col d-none d-xl-block */}
                    <div className="col-lg-3">
                      <SellingInformationOption />
                    </div>
                    {/* ------------ */}

                    {/*---------  Main Contents ------- */}
                    {/* seller-profile-content-col */}
                    <div className="col-lg-9 ">
                      <div className="seller-profile-content-area">
                        {/*---- Header element ---- */}
                        <SellingHeadersPart />
                        {/*  --------- */}

                        {/* ---- Other elements ---- */}
                        {sellerData}
                        {/* ----- */}
                      </div>
                    </div>
                    {/* -------------------------- */}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
