import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { useTranslation } from "react-i18next";
import headerImage from "./../../assets/loginHeaderImage.png";
import ContactForm from "../Home/ContactSection/ContactForm";

export default function Portfolios() {
  const { t, i18n } = useTranslation();
  // Define the items that correspond to each filter

  const externalImg =
    "https://businesso.xyz/assets/front/img/user/breadcrumb.jpg";

  // Define your projects with corresponding filters

  // Filter the projects based on the active filter

  return (
    <>
      {/* ---------- Breadcrumb Section -------- */}
      <section
        className="section-header-part breadcrumb-section bg-img-c lazy entered loaded"
        data-ll-status="loaded"></section>

      {/* ----------  Project Section -------- */}
      {/* light-bg */}
      <section className="contact-area  pb-130 pt-0">
        <br></br>

        <div className="container">
          <div className="row">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61056.26248609084!2d49.81075007185792!3d40.39757827390435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d126c4865e9%3A0xa7eb4f89f3e1e706!2s15%20Khatai%20Ave%2C%20Baku%2C%20Azerbaijan!5e0!3m2!1sen!2sbd!4v1698395903469!5m2!1sen!2sbd"
              width="600"
              height="450"
              style={{ border: 0 }}
              // allowFullscreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="iframe"></iframe>
          </div>

          <div
            className="contact-wrapper-one wow fadeInDown"
            data-wow-delay=".2s"
            style={{
              visibility: "visible",
              animationDelay: "0.2s",
              animationName: "fadeInDown",
            }}>
            <div className="contact-form">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
