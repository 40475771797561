import React from "react";
import "./Hotel.css";
import { useTranslation } from "react-i18next";

const FeatureHotel = ({ data: featureItem = [], FeatureText }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n?.language || "en";

  // render all the item
  const renderFeatureItems = featureItem.map((item) => {
    const { id, price, rating, images, capacity, room } = item || {};

    const name = item[`${lang}_name`] || "";
    const description = item[`${lang}_short_description`] || "";

    const ratingShow = !isNaN(rating)
      ? Array.from(Array(Number(rating)), (x, i) => i + 1).map(
          (item, index) => {
            return (
              <>
                <i
                  key={index}
                  className={`icon fa-regular fa-star `}
                  aria-hidden="true"></i>
                {/* {index + 1 === 3 && <br />} */}
              </>
            );
          }
        )
      : null;

    return (
      <div key={id} className="col-12 col-md-6 col-lg-4">
        <img
          src={`${process.env.REACT_APP_IMAGE_URL}` + images[0].image}
          alt="slider hotel"
          style={{
            height: "244px",
            objectFit: "cover",
            width: "100%",
          }}
        />
        <a href={`/hotel/${id}`} className="w-100">
          <div className="ps-2 pt-2 pb-4 top-hotel-feature">
            <div className="article-meta ">
              <p>{name}</p>
            </div>
            <div className="d-flex justify-content-between">
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
              <div
                className="radio-input"
                style={{ display: "flex", justifyContent: "left" }}>
                <input
                  value="value-1"
                  name="value-radio"
                  id="value-1"
                  type="radio"
                />
                <label htmlFor="value-1">
                  <i class="fa-solid fa-warehouse mr-2"></i>
                  {` ${capacity}`}
                </label>
                <input
                  value="value-1"
                  name="value-radio"
                  id="value-1"
                  type="radio"
                />
                <label htmlFor="value-1">
                  {" "}
                  <i class="fa-solid fa-restroom mr-2"></i>
                  {` ${room}`}
                </label>
              </div>
            </div>
            <div className="article-meta mt-3">
              <p className="article-date">
                <i className="icon fa-regular fa-dollar" aria-hidden="true"></i>
                <time>{price}</time>
              </p>
              <div className="article-comments">{ratingShow}</div>
              <div className="article-comments">
                <p>(275 Reviews)</p>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  });

  return (
    <div
      style={{ marginTop: "30px" }}
      className="feature-hotel section-header-part breadcrumb-section">
      <div className="section-title section-title-left mb-40">
        <span className="sub-title">{FeatureText}</span>
      </div>
      <div className="row row-gap-4 col-gap-1">{renderFeatureItems}</div>
    </div>
  );
};

export default FeatureHotel;
