import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import './PopularAndTrandingNews.css'

function PopularAndTrandingNews() {
    const [toggleButton, setToggleButton] = useState('popular');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await fetch('https://eastgold.az/admin/api/news');
                const json = await res.json();
                setData(json.news);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        // Filter data based on the toggleButton value
        const filtered = data.filter(item => {
            // Adjust the condition based on toggleButton value
            if (toggleButton === 'popular') {
                return item.popular !== 0;
            } else if (toggleButton === 'latest') {
                // Add condition based on 'latest' filtering logic
                // For example, filtering based on 'latest' property
                return item.latest === 1;
            }
            // Add more conditions if needed for other toggleButton values
            return true; // Default condition
        });
        setFilteredData(filtered);
    }, [data, toggleButton]);
    

    return (
        <div className='popularTranding'>
            <div className="btn-group w-100" role="group" style={{ position: 'sticky', top: '0', background: "#22262C" }}>
                <button onClick={() => setToggleButton('latest')} type="button" className={toggleButton === 'latest' ? 'bg-white btn d-flex p-2' : 'bg-transparent btn d-flex p-2'}>
                    <i style={{ fontSize: '16px' }} className="fa-regular fa-clock mr-1"></i>TRENDING
                </button>
                <button onClick={() => setToggleButton('popular')} type="button" className={toggleButton === 'popular' ? 'bg-white btn d-flex p-2' : 'bg-transparent btn d-flex p-2'}>
                    <i style={{ fontSize: '16px' }} className="fa-solid fa-arrow-trend-up mr-1"></i>POPULAR
                </button>
            </div>
            {
                filteredData.map((item, index) => (
                    <Link key={index} to={`/news/${item.id}`}>
                        <div className='d-flex mt-2'>
                            <img className='w-25' src={`${process.env.REACT_APP_IMAGE_URL}/admin/assets/images/news/` + item?.image} />
                            <b className='mx-2'>{item.title}</b>
                        </div>
                    </Link>
                ))
            }
        </div>

    );
}

export default PopularAndTrandingNews;
