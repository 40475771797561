import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const TEST_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const DELAY = 1500;

function Recapta({ captch, setcaptch, handleChange = () => {} }) {
  const _reCaptchaRef = React.useRef(null);

  React.useEffect(() => {
    let timeoutId;

    if (!_reCaptchaRef.current) {
      timeoutId = setTimeout(() => {
        setcaptch((prev) => ({
          ...prev,
          load: true,
        }));
      }, DELAY);
    }

    return () => {
      // Clear the timeout if the component is unmounted before it fires
      clearTimeout(timeoutId);
      _reCaptchaRef.current = null;
    };
  }, [setcaptch]);

  // const handleChange = (value) => {
  //   setcaptch((prev) => {
  //     return {
  //       ...prev,
  //       value,
  //     };
  //   });
  //   // if value is null recaptcha expired
  //   if (value === null)
  //     setcaptch((prev) => {
  //       return {
  //         ...prev,
  //         expired: "true",
  //       };
  //     });
  // };

  const asyncScriptOnLoad = () => {
    setcaptch((prev) => {
      return {
        ...prev,
        callback: "called!",
      };
    });
  };

  const { load } = captch || {};

  return (
    <div className="">
      {load && (
        <ReCAPTCHA
          style={{ display: "inline-block" }}
          theme="dark"
          ref={_reCaptchaRef}
          sitekey={TEST_SITE_KEY}
          onChange={handleChange}
          asyncScriptOnLoad={asyncScriptOnLoad}
          required
        />
      )}
    </div>
  );
}

export default Recapta;
