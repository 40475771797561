import { useEffect, useState } from "react";
import Slider from "react-animated-slider";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import ProductImg1 from "../../assets/gold.jpg";
import CardButton from "../../components/Button/CardButton";

const image_Path = process.env.REACT_APP_IMAGE_URL;

export default function ProductCart({ project, lang }) {
  const { t } = useTranslation();

  const {
    id,
    product_price = "",
    images = [],
    quantities = [],
  } = project || {};

  const [price, setPrice] = useState(product_price);
  const [selectedQunatities, setselectedQunatities] = useState("");

  const handlerSelectGram = (props) => {
    const { productId, quantityId } = props;

    setselectedQunatities((prev) => {
      const selete = `${quantityId}${productId}`;
      if (prev === selete) {
        return "";
      } else {
        return selete;
      }
    });

    const selectedQuantities = quantities.filter(
      (quantity) => quantity.id === quantityId
    );

    // if any quantities find
    setPrice(selectedQuantities[0].price);
  };

  useEffect(() => {
    if (!selectedQunatities) {
      setPrice(product_price);
    }
  }, [selectedQunatities, product_price]);

  const productName = project[`${lang}_product_name`];
  const productDesc = project[`${lang}_product_desc`];

  // user want to buy any gold bar
  const handlerBuy = () => {
    if (quantities.length > 0 && !selectedQunatities) {
      Swal.fire({
        title: "",
        text: `Please Select Any of Gold Bar quantity of ${productName} `,
        icon: "info",
      });
    } else {
      // here if any quantities find.
      console.log("you can buy this gold Bar.", { productName });
    }
  };

  return (
    <div
      className={`isotope-item col-lg-4 col-sm-6 ${project.itemClass || ""}`}>
      <div className="project-box hover-style">
        <Slider autoplay={3000}>
          {images?.length === 0 ? (
            <img alt="product" src={ProductImg1} />
          ) : (
            images?.map((item) => {
              const { image, id } = item || {};
              const imageUrl = `${image_Path}${image}`;

              return (
                <img
                  key={id}
                  alt="product"
                  src={imageUrl}
                  style={{ width: "100%", height: "auto" }}
                />
              );
            })
          )}
        </Slider>

        <div className="project-desc text-center">
          {/* <p>{project.category}</p> */}

          <p>{productDesc}</p>

          <button
            style={{ background: "#162542" }}
            onClick={handlerBuy}
            href="tel:+0777121090"
            className="btn p-2 mb-2 mt-2">
            {t("Buy now")}
          </button>

          {/* <a href="#" className="project-link">
        <i className="fas fa-arrow-right"></i>
      </a> */}
        </div>
      </div>
      <div
        className="thumb bg-img-c lazy entered error "
        style={{ textAlign: "center" }}>
        <h4>{project.title}</h4>

        <CardButton
          ProductQuantities={quantities}
          productId={id}
          handler={handlerSelectGram}
          selectedQunatities={selectedQunatities}
        />
        <div className="mb-4"></div>
        <h4>
          {
            // {product_price} AZN <span>₼</span>
          }
          <p className="mb-3">{productName}</p>
          <b>
            {
              //formatNumberWithCommas(product_price)
            }
            {price} <span style={{ fontSize: "17px" }}>₼</span>
          </b>
        </h4>
      </div>
    </div>
  );
}
