import React, { useEffect } from "react";
import { useState } from "react";
import "./Hotels.css";
import RadioButton from "../../components/Button/RadioButton";
import { useParams } from "react-router-dom";
import { PiShootingStarThin } from "react-icons/pi";
import useGetEffectItems from "../../Hooks/useSingleItemGetEffect";
import { useTranslation } from "react-i18next";

const category = [{ name: "Description" }, { name: "Reviews" }];
const contact_Number = process.env.REACT_APP_CONTACT_NUMBER || "";

const Hotels = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n?.language || "en";

  const [activeFilter, setActiveFilter] = useState("Description");

  const [open, setOpen] = useState(false);

  const { id: hotelId } = useParams();
  // get hotel single id
  const { data, error } = useGetEffectItems(`hotelsWise/${hotelId}`);

  const {
    booking_end,
    booking_start,
    capacity,
    created_at,
    // description,
    // highlight,
    id,
    // location,
    // name,
    phone,
    price,
    rating,
    room,
    // short_description,
    status,
    updated_at,
    images,
  } = data || {};

  const ratingShow = !isNaN(rating)
    ? Array.from(Array(Number(rating)), (x, i) => i + 1).map((item, index) => {
        return (
          <i
            key={index}
            className="icon fa-regular fa-star"
            aria-hidden="true"></i>
        );
      })
    : null;

  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };

  const [sliderImage, setSliderImage] = useState([]);

  useEffect(() => {
    if (images?.length > 0) {
      setSliderImage(
        images?.map((item) => {
          return {
            image: process.env.REACT_APP_IMAGE_URL + item.image,
          };
        })
      );
    }
  }, [images]);

  const [slideNumber, setSlideNumber] = useState(0);

  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber =
        slideNumber === 0 ? sliderImage.length - 1 : slideNumber - 1;
    } else {
      newSlideNumber =
        slideNumber === sliderImage.length - 1 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };

  if (data === null) return;

  const name = data[`${lang}_name`] || "";
  const description = data[`${lang}_description`] || "";
  const location = data[`${lang}_location`] || "";
  const short_description = data[`${lang}_short_description`] || "";
  const highlight = data[`${lang}_highlight`] || "";

  return (
    <div>
      <div
        className="hotelContainer "
        style={{ marginTop: "150px", marginBottom: "100px" }}>
        {open && (
          <div className="slider">
            <i
              className="fa-solid fa-xmark close"
              onClick={() => setOpen(false)}></i>

            <i
              className="fa-solid fa-circle-arrow-left arrow"
              onClick={() => handleMove("l")}></i>

            <div className="sliderWrapper">
              <img
                src={sliderImage[slideNumber].image}
                alt=""
                className="sliderImg"
              />
            </div>

            <i
              className="fa-solid fa-circle-arrow-right arrow"
              onClick={() => handleMove("r")}></i>
          </div>
        )}

        <div className="container hotelWrapper">
          <h1 className="hotelTitle">{name}</h1>
          <div className="hotelAddress">
            <i className="fa-solid fa-location-dot"></i>
            <span>{location}</span>
          </div>

          <div className="hotelImages">
            {sliderImage.map(({ image }, i) => {
              return (
                <div className="hotelImgWrapper" key={i}>
                  <img
                    onClick={() => handleOpen(i)}
                    src={image}
                    alt=""
                    className="hotelImg h-100"
                  />
                </div>
              );
            })}
          </div>

          <div className="hotelDetails">
            <div className="hotelDetailsTexts">
              <div className="pl-2 d-flex justify-content-start align-items-center">
                <PiShootingStarThin
                  className="pr-2"
                  style={{ color: "#b58b5a", fontSize: "32px" }}
                />
                <div
                  className="text-white"
                  dangerouslySetInnerHTML={{ __html: highlight }}></div>
              </div>

              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                  {/* Filter buttons */}
                  <ul
                    className="project-nav project-isotope-filter"
                    style={{ display: "flex", justifyContent: "left" }}>
                    {category.map(({ name }, id) => {
                      return (
                        <li
                          key={id}
                          onClick={() => {
                            setActiveFilter(name);
                          }}
                          className={activeFilter === name ? "active" : ""}>
                          {name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-lg-12 col-md-12">
                  {activeFilter === "Description" && (
                    <div
                      className="hotelDesc"
                      dangerouslySetInnerHTML={{ __html: description }}></div>
                  )}

                  {activeFilter === "Reviews" && (
                    <>
                      <p className="hotelDesc"></p>
                      <div className="article-meta mt-3">
                        <p className="article-comments">{ratingShow}</p>
                        <p className="article-comments">(249 Reviews)</p>
                      </div>
                    </>
                  )}

                  {/* radio button */}
                  <div className="radio-input mt-4">
                    <input
                      value="value-1"
                      name="value-radio"
                      id="value-1"
                      type="radio"
                    />

                    <label htmlFor="value-1">
                      {" "}
                      <i className="fa-solid fa-wifi mr-1 mr-lg-2 mr-md-2"></i>
                      Free Wifi
                    </label>
                    <input
                      value="value-2"
                      name="value-radio"
                      id="value-2"
                      type="radio"
                    />
                    <label htmlFor="value-2">
                      <i className="fa-solid fa-bed mr-1 mr-lg-2 mr-md-2"></i>
                      {`${capacity} capacity`}
                    </label>
                    <input
                      value="value-3"
                      name="value-radio"
                      id="value-3"
                      type="radio"
                    />
                    <label htmlFor="value-3">
                      {" "}
                      <i className="fa-solid fa-person-swimming mr-1 mr-lg-2 mr-md-2"></i>
                      Swimming Pool
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="hotelDetailsPrice">
              <div className="ps-2 pt-2 pb-4">
                <div className="article-meta">
                  <p>{name}</p>
                </div>
                <div
                  className="article-meta"
                  dangerouslySetInnerHTML={{ __html: short_description }}></div>
                <div className="article-meta mt-3">
                  <p className="article-date">
                    <i
                      className="icon fa-regular fa-dollar"
                      aria-hidden="true"></i>
                    <time>{price}</time>
                  </p>

                  <p className="article-comments mb-2">{ratingShow}</p>
                </div>

                <button className="reseve-button">
                  <a href={`tel: ${contact_Number}`}>Reserve or Book Now!</a>
                </button>
              </div>
            </div>
          </div>

          <div className="hotelDetails">
            <div className="hotelDetailsTexts">
              <div className="hotelDesc">
                <h1 className="hotelTitle">Guest Access</h1>
                <ul>
                  <li>Visitors Strictily Not Allowed</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hotels;
