import React from "react";
import { useTranslation } from "react-i18next";

const aboutUstext = [
  "The sheer advantage of the fact that you can liquidate a gold bar for cash when you require it",
  "That is the reason why families and businesses through the ages have purchased gold bars as they can be assured of its safety as an investment tool whether the times are good or bad",
  "There is always a utilization factor linked to gold bar. Sooner or later you can leverage it for weddings, family occasions, or festivals",
  "With the rising currency risk in national and international markets, gold has always been a priced commodity that can always be relied upon to ensure that your investments are safe",
  "A gold bar when compared to any other asset such as building, vehicles, or land is an asset that is easy to move to sell if the need arises",
  "There is a reason that of all the precious metals, gold continues to be the favorite of investors across the globe. Investors buy 24k gold bars as a way of diversifying risk in a world where there is significant uncertainty in the markets and economy",
];

const Whyinvestgoldbar = () => {
  const { t, i18n } = useTranslation();
  const aboutUsTextRender = aboutUstext.map((item, i) => {
    return (
      <li key={i} className="mb-3 text-white">
        <a href="/" style={{ textAlign: "justify" }}>
          {t(`${item}`)}
        </a>
      </li>
    );
  });
  return (
    <div className="example mt-4">
      <div className="the-image"></div>
      <div
        className="about-content-box about-content-box-two wow fadeInLeft"
        data-wow-delay=".3s"
        style={{ visibility: "visible", animationDelay: "0.3s" }}>
        <div className="section-title section-title-left mb-40">
          <h2 className="mb-4">{t("Why should you invest in gold bar?")}</h2>
          <ul>{aboutUsTextRender}</ul>
        </div>
      </div>
    </div>
  );
};

export default Whyinvestgoldbar;
