import "./App.css";
//import "./assets/css/bootstrap.min.css";
import { Switch, Route } from "react-router-dom";
import Home from "../src/pages/Home/Home";
import Products from "../src/pages/Products/Products";
import About from "../src/pages/About/About";
import Contact from "../src/pages/Contact/Contact";
import Portfolios from "../src/pages/Portfolios/Portfolios";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import SellerDashboard from "./pages/SellerDashboard/SellerDashboard";
import BuyerDashboard from "./pages/BuyerDashboard/BuyerDashboard";
import PartnerDashboard from "./pages/PartnerDashboard/PartnerDashboard";
import SellerOrders from "./pages/SellerDashboard/orders";
import SellerProfile from "./pages/SellerDashboard/Profile";
import BuyerOrders from "./pages/BuyerDashboard/Order";
import PartnerOrders from "./pages/PartnerDashboard/Order";
import { useState } from "react";
import { useEffect } from "react";
import { AuthProvider } from "./Auth/AuthProvider";
import Hotel from "./pages/Hotel/Hotel";
import Tour from "./pages/Home/Tour/Tour";
import News from "./pages/News/News";
import Newses from "./pages/Newses/Newses";
import Hotels from "./pages/Hotels/Hotels";
import Tours from "./pages/Tours/Tours";
import DesignGoldBar from "./pages/DesignGoldBar/DesignGoldBar";
import BuyBack from "./components/BuyBack/BuyBack";

function App() {
  const [buyerData, setBuyerData] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [userBuyerData, setUserBuyerData] = useState([]);
  const [userPartnerData, setUserPartnerData] = useState([]);
  const apiGetBuyerDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Url}/allbuyers_details`
      );
      const jsondata = await response.json();
      setBuyerData(jsondata);
    } catch (error) {
      console.error("API request error:", error);
    }
  };
  const apiGetPartnerDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Url}/allpartners_details`
      );
      const jsondata = await response.json();
      setPartnerData(jsondata);
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  useEffect(() => {
    // apiGetSellerDetails();
    apiGetBuyerDetails();
    apiGetPartnerDetails();
  }, []);
  useEffect(() => {
    if (buyerData.length > 0) {
      const userNewBuyerData = buyerData.map((items) => {
        return items.map((i) => {
          return i;
        });
      });
      setUserBuyerData(userNewBuyerData);
    }
  }, [buyerData]);

  useEffect(() => {
    if (partnerData.length > 0) {
      const userNewPartnerData = partnerData.map((items) => {
        return items.map((i) => {
          return i;
        });
      });
      setUserPartnerData(userNewPartnerData);
    }
  }, [partnerData]);

  // useEffect(() => {
  //   if (userBuyerData.length > 0) {
  //     console.log("Updated userBuyerData:", userBuyerData);
  //   }
  // }, [userBuyerData]);

  // useEffect(() => {
  //   if (userPartnerData.length > 0) {
  //     console.log("Updated userPartnerData:", userPartnerData);
  //   }
  // }, [userPartnerData]);

  const [isLogedIn, setIsLogedIn] = useState(false);

  const handleLoginClick = () => {
    setIsLogedIn(true);
  };

  // preLoader full page start here
  const [loading, setLoading] = useState(true);
  const [checker, setChecker] = useState(false);

  async function DataLoad() {
    const Response = await fetch(
      `https://eastgold.az/admin/api/language_translation`
    );

    if (!Response.ok) {
      throw new Error("Error loading language translation");
    }

    setChecker(true);
  }

  useEffect(() => {
    DataLoad();

    const spinner = document.getElementById("spinner");

    if (spinner) {
      const timeoutId = setTimeout(() => {
        spinner.style.display = "none";
        setLoading(false);
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);
  // preLoader full page end here

  return (
    !loading && (
      <AuthProvider>
        <div className="App">
          {/* ---------- Navigation Bar ---------- */}
          <Navbar />
          {/* ---------- Routings ---------- */}
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/products">
              <Products />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/hotel">
              <Hotel />
            </Route>
            <Route exact path="/hotel/:id">
              <Hotels />
            </Route>
            <Route exact path="/tour">
              <Tour />
            </Route>
            <Route exact path="/tour/:id">
              <Tours />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/desgin">
              <DesignGoldBar />
            </Route>
            <Route exact path="/news">
              <News />
            </Route>
            <Route exact path="/buyback">
              <BuyBack />
            </Route>
            <Route exact path="/news/:id">
              <Newses />
            </Route>
           

            <Route exact path="/portfolios">
              <Portfolios />
            </Route>
            <Route exact path="/login">
              <Login
                isLogedIn={isLogedIn}
                setIsLogedIn={setIsLogedIn}
                handleLoginClick={handleLoginClick}
              />
            </Route>
            {/* <Route exact path="/signup">
          <SignUp />
        </Route> */}
            {/* ---- Seller ---- */}
            <Route exact path="/seller-dashboard">
              {userBuyerData.length > 0 && userPartnerData.length > 0 ? (
                <SellerDashboard
                  userBuyerData={userBuyerData}
                  userPartnerData={userPartnerData}
                />
              ) : (
                <p>Loading...</p>
              )}
            </Route>
            <Route exact path="/seller-transitions">
              <SellerOrders isLogedIn={isLogedIn} setIsLogedIn={setIsLogedIn} />
            </Route>
            <Route exact path="/seller-profile">
              {userBuyerData.length > 0 && userPartnerData.length > 0 ? (
                <SellerProfile
                  userBuyerData={userBuyerData}
                  userPartnerData={userPartnerData}
                />
              ) : (
                <p>Loading...</p>
              )}
            </Route>
            {/* ------- */}
            {/* ---- Buyer ---- */}
            <Route exact path="/buyer-dashboard">
              <BuyerDashboard userBuyerData={userBuyerData} />
            </Route>
            <Route exact path="/buyer-transitions">
              <BuyerOrders
                isLogedIn={isLogedIn}
                setIsLogedIn={setIsLogedIn}
                userBuyerData={userBuyerData}
              />
            </Route>
            {/* ---- partner ---- */}
            <Route exact path="/partner-dashboard">
              <PartnerDashboard userPartnerData={userPartnerData} />
            </Route>
            <Route exact path="/partner-transitions">
              <PartnerOrders
                isLogedIn={isLogedIn}
                setIsLogedIn={setIsLogedIn}
                userPartnerData={userPartnerData}
              />
            </Route>
          </Switch>

          {/* ---------- Footer ---------- */}
          <Footer />
        </div>
      </AuthProvider>
    )
  );
}

export default App;
