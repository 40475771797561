import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Login.css";
import Swal from "sweetalert2";
import Recapta from "../../components/Recapta";
import headerImage from "./../../assets/loginHeaderImage.png";
import { useAuth } from "../../Auth/UseAuth";

async function loginUser(credentials) {
  return fetch(`${process.env.REACT_APP_Url}/client_login`, {
    method: "POST",
    body: JSON.stringify(credentials),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function Login(props) {
  const { SignInwithemailLogin, FacebookLogin } = useAuth();

  const [captch, setcaptch] = React.useState({
    callback: "not fired",
    value: "",
    load: true,
    expired: "false",
  });

  const [credentialsInfo, setCredentialsInfo] = useState({
    user: "",
    password: "",
  });

  const externalImg =
    "https://businesso.xyz/assets/front/img/user/breadcrumb.jpg";

  let user = credentialsInfo.user;
  let password = credentialsInfo.password;
  let history = useHistory();

  //console.log(seller.email)

  const handleChange = (e) => {
    setCredentialsInfo({ ...credentialsInfo, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      history.push("/login");
    }
    if (token) {
      history.push("/home");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { value: _recaptcha } = captch;

    if (!_recaptcha) return;

    const response = await loginUser({
      user,
      password,
      _recaptcha,
    });

    if ("api_token" in response) {
      Swal.fire("Successfully!", response.message, "success", {
        buttons: false,
        timer: 2000,
      });

      localStorage.setItem("token", response["data"]["account_mode"]);
      localStorage.setItem("user", JSON.stringify(response["data"]));
      // window.location.href = "/portfolio";

      if (response.data.account_mode === "Seller") {
        // history.push("/seller-dashboard")

        window.location.href = "/seller-profile";
      } else if (response.data.account_mode === "Buyer") {
        window.location.href = "/buyer-transitions";
        //  history.push("/buyer-transitions")
      } else if (response.data.account_mode === "Partner") {
        //  history.push("/partner-transitions")
        window.location.href = "/partner-transitions";
      } else {
        alert("Invalid email");
      }
    } else {
      Swal.fire("Error", response.message, "error");
    }
  };

  const handleChanges = (value) => {
    setcaptch((prev) => {
      return {
        ...prev,
        value,
      };
    });
    // if value is null recaptcha expired
    if (value === null)
      setcaptch((prev) => {
        return {
          ...prev,
          expired: "true",
        };
      });
  };

  return (
    <>
      {/* ---------- Breadcrumb Section -------- */}
      <section
        className="section-header-part breadcrumb-section bg-img-c lazy entered loaded"
        // data-bg={headerImage}
        data-ll-status="loaded"
        // style={{ backgroundImage: `url(${headerImage})` }}
      >
        <div className="container">
          <div className="breadcrumb-text">
            {
              // <h1 className="page-title">Login</h1>
              // <ul>
              //   <li>
              //     <a href="/">Home</a>
              //   </li>
              //   <li>Login</li>
              // </ul>
            }
          </div>
        </div>
        {/* <div className="breadcrumb-shapes">
          <div className="one"></div>
          <div className="two"></div>
        </div> */}
      </section>

      {/* ---------   Login Part ------------ */}
      <div className="user-area-section section-gap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="user-form">
                <div className="title mb-3">
                  <h4>Login</h4>
                </div>
                <form
                  data-v-32ab5d56=""
                  noValidate="novalidate"
                  className="BaseForm register-form"
                  data-hs-cf-bound="true"
                  onSubmit={handleSubmit}>
                  <div className="form_group">
                    <label>User Id *</label>
                    <input
                      type="text"
                      placeholder="Enter User Id"
                      className="form_control"
                      name="user"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="form_group">
                    <label>Password *</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Enter Password"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>

                  <div className="form_group">
                    {
                      // recapta code here
                    }
                    <Recapta
                      captch={captch}
                      setcaptch={setcaptch}
                      handleChange={handleChanges}
                    />
                  </div>
                  <div className="form_group">
                    <button
                      className="btn"
                      data-v-1ea8460d=""
                      data-v-32ab5d56=""
                      tabIndex="1"
                      type="submit"
                      block=""
                      color="primary">
                      <span className="v-btn__content"> Login Now</span>
                    </button>
                  </div>
                </form>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {
                    // <button
                    //   onClick={() => {
                    //     FacebookLogin();
                    //   }}
                    //   className="loginBtn loginBtn--facebook">
                    //   Login with Facebook
                    // </button>
                    // <button
                    //   onClick={() => {
                    //     SignInwithemailLogin();
                    //   }}
                    //   className="loginBtn loginBtn--google">
                    //   Login with Google
                    // </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
