import React, { useEffect, useState } from "react";
import i18n from "i18next";
// import en from "../assets/en.jpg";
// import ru from "../assets/bn.jpg";
// import az from "../assets/az.webp";
// import { Link } from "react-router-dom";

const flagData = [
  {
    logo: "./lang/az.webp",
  },
  {
    logo: "./lang/ru.png",
  },
  {
    logo: "./lang/en.jpg",
  },
];

export default function ChangeLang() {
  const [language, setLangugae] = useState(
    () => localStorage.getItem("lang") || "az"
  );

  const [languageinfo, setLangugaeInfo] = useState([]);

  useEffect(() => {
    fetch(`https://eastgold.az/admin/api/language_country`)
      .then((response) => response.json())
      .then((data) => {
        setLangugaeInfo(
          data?.language_country?.map((item) => {
            const { language_code, language_name } = item || {};
            const langData = {
              code: language_code,
              name: language_name,
            };
            if (language_code === "en") {
              return {
                logo: "./lang/en.jpg",
                ...langData,
              };
            }
            if (language_code === "az") {
              return {
                logo: "./lang/az.webp",
                ...langData,
              };
            }
            if (language_code === "ru") {
              return {
                logo: "./lang/ru.png",
                ...langData,
              };
            }
          })
        );
      });
  }, []);

  useEffect(() => {
    const changeLanguage = async () => {
      if (!i18n.languages) {
        await i18n.changeLanguage(language);
      }

      localStorage.setItem("lang", language);
      await i18n.changeLanguage(language);
    };

    changeLanguage();
  }, [language]);

  const langChangeInfo = (e) => {
    setLangugae(e);
  };

  return (
    <div>
      <div className="version col-4">
        <div className="dropdown show">
          <a
            className="dropdown-toggle"
            href="/"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ display: "flex" }}>
            {(() => {
              if (language == "en") {
                return (
                  <img
                    className="currentLanFlag"
                    src={flagData[2].logo}
                    style={{ width: "80px", height: "auto" }}
                    alt="Language Flag"
                  />
                );
              } else if (language === "ru") {
                return (
                  <img
                    className="currentLanFlag"
                    src={flagData[1].logo}
                    style={{ width: "80px", height: "auto" }}
                    alt="Language Flag"
                  />
                );
              } else {
                return (
                  <img
                    className="currentLanFlag"
                    src={flagData[0].logo}
                    style={{ width: "80px", height: "auto" }}
                    alt="Language Flag"
                  />
                );
              }
            })()}
          </a>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {languageinfo.map(({ code, name, logo }) => {
              return (
                <div
                  key={code}
                  className="dropdown-item"
                  onClick={() => langChangeInfo(code)}
                  style={{
                    textDecoration: "none",
                    padding: "5px",
                  }}>
                  <img
                    className="flag"
                    src={logo}
                    alt=""
                    style={{ width: "20px", height: "15px" }}
                  />{" "}
                  &nbsp; {name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
