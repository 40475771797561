import { useState } from "react";
import React, { useEffect } from "react";
import CardButton from "../../components/Button/CardButton";
import "./DesgingGoldbar.css";
import Swal from "sweetalert2";
import { IoIosCloudUpload } from "react-icons/io";
import { useTranslation } from "react-i18next";

const inititalState = {
  name: "",
  phoneNumber: "",
  size: "",
  price: "",
  image: [],
};

export default function DesginGoldBarForm({
  loader,
  setFiles,
  setselectedQunatities,
  selectedQunatities,
  handleOrder,
  setRightSideImage,
  setLeftSideImage,
}) {
  const { t, i18n } = useTranslation();

  const [formdata, setFormData] = useState(inititalState);
  const [seletedProduct, setseletedProduct] = useState([]);
  const [price, setSeletedPrice] = useState("");

  useEffect(() => {
    if (selectedQunatities === "") {
      setRightSideImage(null);
      setLeftSideImage(null);
    }
    // if any quantities not change
    if (!selectedQunatities) {
      const priceText = `${t("_")}`;
      setSeletedPrice(priceText);
      return;
    }

    // selecteQunantity and product has same quantity
    const selectedProductQuantites = seletedProduct.find((item) =>
      item.quantity.includes(selectedQunatities)
    );

    const { price } = selectedProductQuantites || {};

    if (!price) {
      setSeletedPrice("_");
    } else {
      setSeletedPrice(price);
    }
  }, [selectedQunatities]);

  const handlerQuantity = (props) => {
    const { quantityId } = props;

    const quantitySeleted =
      seletedProduct.find((item) => item.id === quantityId)["quantity"] || "";

    setselectedQunatities((prev) => {
      //   const selete = `${quantityId}`;
      if (prev === quantitySeleted) {
        return "";
      } else {
        return quantitySeleted;
      }
    });
  };

  const handlerInput = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleChange = (e) => {
    const selectedFiles = e.target.files;

    Array.from(selectedFiles).forEach((file) => {
      // Check if file is an image
      const validImageTypes = ["image/png", "image/jpeg", "image/gif"];
      if (!validImageTypes.includes(file.type)) {
        Swal.fire({
          text: "You have given the wrong file type. Please choose an image file.",
          icon: "info",
        });

        return;
      }

      // Read the image file
      const reader = new FileReader();
      reader.onload = function (event) {
        const img = new Image();
        img.onload = function () {
          if (img.height < 600 && img.width < 600) {
            Swal.fire({
              text: "Please Give Better Qualtiy Image",
              icon: "info",
            });
          }
          const imageData = event.target.result;

          if (selectedFiles.length === 1) {
            const imageFile = Array.from(Array(2), (x) => {
              return {
                src: imageData,
              };
            });
            //  console.log(imageData);
            setFiles(imageFile);
          } else {
            setFiles((prevFiles) => [...prevFiles, { src: imageData }]);
          }
          setselectedQunatities(null);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  // here come single for choosing the size product data
  useEffect(() => {
    fetch(`${process.env.REACT_APP_Url}/products`)
      .then((res) => res.json())
      .then((data) => {
        const checkGoldStatus =
          data.find((item) => item.gold_status === 1)["quantities"] || [];
        setseletedProduct(checkGoldStatus);
      });
  }, []);

  return (
    <div className="w-100 mt-4 mt-md-0">
      <div className="d-flex justify-content-center">
        <input
          className="d-none"
          type="file"
          accept="image/png, image/gif, image/jpeg"
          multiple
          id="my-file"
          onChange={handleChange}
        />
        <label
          tabIndex="0"
          htmlFor="my-file"
          className="d-block col-12 col-md-6 px-3 py-2 bg-black text-white text-center"
          style={{ cursor: "pointer" }}>
          {t(`CHOOSE IMAGE`)}
          <IoIosCloudUpload
            className="p-1"
            style={{
              color: "#b68c5a",
            }}
          />
        </label>
      </div>
      <div className="d-block d-md-flex gap-4 my-4">
        <div className="w-50 design-box-w">
          <p className="d-flex justify-content-center align-items-center h-100 bg-black text-white text-center text-uppercase">
            {t(`Name`)}
          </p>
        </div>
        <div className="w-50 design-box-w">
          <div className="d-block w-100 bg-white">
            <input
              type="text"
              placeholder={t("NAME FIELD")}
              className="pl-4 text-black form-control"
              name="name"
              onChange={handlerInput}
            />
          </div>
        </div>
      </div>
      <div className="d-block d-md-flex  gap-4 my-4">
        <div className="w-50 design-box-w">
          <p className="d-flex justify-content-center align-items-center h-100 bg-black text-white text-center text-uppercase">
            {t(`Phone number`)}
          </p>
        </div>
        <div className="w-50 design-box-w">
          <div className="d-block w-100 bg-white">
            <input
              type="text"
              placeholder={t("NUMBER FIELD")}
              name="phoneNumber"
              onChange={handlerInput}
              className="pl-4 text-black form-control"
            />
          </div>
        </div>
      </div>
      <div className="row gap-4 my-4">
        <div className="col-12 col-md-6">
          <p className="d-block px-3 py-2 bg-black text-white text-center text-uppercase">
            {t(`Select size`)}
          </p>
        </div>
        <div className="col-12 col-md-5 d-flex align-items-center">
          <div className="w-100 text-center">
            <CardButton
              ProductQuantities={seletedProduct}
              handler={handlerQuantity}
              selectedQunatities={selectedQunatities}
            />
          </div>
        </div>
      </div>
      <div className="d-flex gap-4 my-4">
        <div className="w-50">
          <p className="d-flex justify-content-center align-items-center h-100 bg-black text-white text-center text-uppercase">
            {t(`Price`)}
          </p>
        </div>
        <div className="w-50">
          <div className="d-block w-100 bg-white">
            <input
              type="text"
              placeholder={t("PRICE FIELD")}
              value={price === "_" ? `${t("PRICE FIELD")}` : price}
              className="pl-4 text-black form-control"
              name="name"
              onChange={handlerInput}
              disabled
            />
          </div>
        </div>
      </div>
      <div className="text-center">
        <button
          onClick={() =>
            handleOrder({ ...formdata, price, size: selectedQunatities })
          }
          className="btn text-uppercase"
          style={{
            background: "#b68c5a",
            color: "white",
          }}
          disabled={loader}>
          {loader ? `${t("ORDER PROCESSING")}...` : `${t("Order now")}`}
        </button>
      </div>
    </div>
  );
}
