import React from "react";
import { useTranslation } from "react-i18next";
import "./About.css";
import AboutUsImageTwo from "../../../assets/whygoldsection.jpg";
import Whyinvestgoldbar from "../../Whyinvestgoldbar";
import AboutUs from "./AboutUs";

export default function AboutSection() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <section className="about-area pt-120 pb-70" id="about">
        <div className="container">
          <AboutUs />

          <div className="image-containerbox">
            <img src={AboutUsImageTwo} alt="Your  Description" />
          </div>

          {/* this is Why should you invest in gold bar? part start*/}
          <Whyinvestgoldbar />
          {/* this is Why should you invest in gold bar? part end*/}
        </div>
      </section>
    </>
  );
}

// <div className="row">
// <div className="col-lg-5">
//   <div className="about-img-box about-img-box-two d-none d-md-none d-lg-block ">
//     <div
//       className="about-img-one  wow fadeInUp"
//       data-wow-delay=".45s"
//       style={{ visibility: "visible", animationDelay: "0.45s" }}>
//       <img
//         className="lazy entered loaded"
//         data-src={AboutUsImageTwo}
//         alt="about"
//         data-ll-status="loaded"
//         src={AboutUsImageTwo}
//       />
//     </div>
//   </div>
// </div>
// <div className="col-lg-7">
//   <div
//     className="about-content-box about-content-box-one wow fadeInLeft"
//     data-wow-delay=".3s"
//     style={{ visibility: "visible", animationDelay: "0.3s" }}>
//     <div className="section-title section-title-left mb-40">
//       <h2 className="mb-4">
//         {t("Why should you invest in gold bar?")}
//       </h2>
//       <ul>{aboutUsTextRender}</ul>
//     </div>
//   </div>
// </div>
// </div>
