import { createContext } from "react";
import UserFirebaseEmailSystem from "../FireBase/UserFirebaseEmailSystem";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const authSystem = UserFirebaseEmailSystem();

  return (
    <AuthContext.Provider value={authSystem}>{children}</AuthContext.Provider>
  );
};
