import Swal from "sweetalert2";

export async function AddFormData(credentials) {
  const csrfToken = document.head
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute("content");

  const response = await fetch(`${process.env.REACT_APP_Url}/goldbar_add`, {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());

  if (response["alert-type"] == "success") {
    Swal.fire(
      "Successfully Order Sent!",
      `We will contact you as soon as possible`,
      "success",
      {
        buttons: false,
        timer: 8000,
      }
    );

    window.location.reload();
  } else {
    Swal.fire("", response.message, "info", {
      buttons: false,
      timer: 8000,
    });

    window.location.reload();
  }
}
