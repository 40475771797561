export const ImageSetter = (
  editor,
  logoChange,
  LogoImg1,
  LogoImg2,
  backLogo,
  x,
  y,
  w,
  h
) => {
  return new Promise(async (resolve, reject) => {
    const dataURL = await editor.getImage().toDataURL();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set canvas dimensions to match AvatarEditor component
    canvas.width = 1000;
    canvas.height = 1544;

    // Set canvas background to transparent
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Create an image element and set its source to the data URL
    const img = new Image();
    img.src = dataURL;

    // When the image is loaded, draw it onto the canvas
    img.onload = () => {
      // Apply fixed border radius using a mask
      const borderRadius = 120; // Set your fixed border radius value

      ctx.save();
      ctx.beginPath();

      // Top left corner
      ctx.moveTo(borderRadius, 0);
      ctx.lineTo(canvas.width - borderRadius, 0);
      ctx.quadraticCurveTo(canvas.width, 0, canvas.width, borderRadius);

      // Top right corner
      ctx.lineTo(canvas.width, canvas.height - borderRadius);
      ctx.quadraticCurveTo(
        canvas.width,
        canvas.height,
        canvas.width - borderRadius,
        canvas.height
      );

      // Bottom right corner
      ctx.lineTo(borderRadius, canvas.height);
      ctx.quadraticCurveTo(0, canvas.height, 0, canvas.height - borderRadius);

      // Bottom left corner
      ctx.lineTo(0, borderRadius);
      ctx.quadraticCurveTo(0, 0, borderRadius, 0);

      ctx.closePath();
      ctx.clip();
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      ctx.restore();

      // Draw additional images onto the canvas
      const logo = logoChange ? LogoImg2 : LogoImg1;
      const firstImage = new Image();
      firstImage.src = logo;
      firstImage.onload = () => {
        // Adjust drawing coordinates relative to canvas dimensions
        ctx.drawImage(
          firstImage,
          canvas.width * 0.35,
          canvas.height * 0.89,
          canvas.width * 0.3,
          canvas.height * 0.09
        );

        const secondImage = new Image();
        secondImage.src = backLogo;
        secondImage.onload = () => {
          // Adjust drawing coordinates relative to canvas dimensions
          ctx.drawImage(
            secondImage,
            canvas.width * x,
            canvas.height * y,
            canvas.width * w,
            canvas.height * h
          );

          // Convert the canvas to a data URL and update the state
          const zoomedDataURL = canvas.toDataURL();

          // Resolve the Promise with the data URL
          resolve(zoomedDataURL);
        };
      };
    };
  });
};
