import React, { useState, useEffect } from "react";
import OrdersImg from "../../assets/my-orders.png";
import { Link } from "react-router-dom";
import "../SellerDashboard/Seller.css";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import "../styles.css";
import GolgImg from "../../assets/gold.png";
import { formatNumberWithCommas } from "../../utils/FormatNumberWithCommas";
import "./Buyer.css";
import SortIcon from "@material-ui/icons/ArrowDownward";
import { ModalDialog } from "../../components/Modal/Index";

// import DashboardImg from "../../assets/dashboard.png";
// import ProfileImg from "../../assets/profile.png";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import { useTranslation } from "react-i18next";

export default function Orders() {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [Comission, setComission] = useState([]);
  const [TotalCommition, setTotalCommition] = useState([]);
  const [GoldBarPrice, setGoldBarPrice] = useState([]);
  const [AvgGoldBarPrice, setAvgGoldBarPrice] = useState([]);
  const [Count, setCount] = useState([]);
  const [Withdraw, setWithdraw] = useState([]);

  // for modal
  const [inputNumber, setInputNumber] = useState(0);
  const [open, setOpen] = useState(false);

  const [buyerError, setBuyerError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  let history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));

  const apiGetSellerTransitions = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Url}/buyer_product_details?buyer_id=${user.user_id_info}`
      );
      const jsondata = await response.json();
      const { status, message } = jsondata || {};
      jsondata?.data?.map((items) => {
        //console.log(items)
      });
      if (status) {
        setData(jsondata.data);
        setComission(jsondata.comission);
        setTotalCommition(jsondata.total_commition);
        setGoldBarPrice(jsondata.gold_bar_price.gold_bar_price);
        setWithdraw(jsondata.withdraw);
        setAvgGoldBarPrice(jsondata.avg_gold_bar_price);
        setCount(jsondata.count);
      } else {
        setBuyerError(message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("API request error:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      history.push("/login");
    }
    apiGetSellerTransitions();
  }, []);

  async function formData(credentials) {
    const csrfToken = document.head
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content");

    const response = await fetch(`${process.env.REACT_APP_Url}/fetchAddress`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },

      body: JSON.stringify(credentials),
    }).then((data) => data.json());

    if (response.status == true) {
      setOpen(false);

      Swal.fire(
        "Successfully!",
        `Your withdrawn Location Address is ${response.message}`,
        "success",
        {
          buttons: false,
          timer: 8000,
        }
      );

      window.location.reload();
    } else {
      setOpen(false);
      Swal.fire("", response.message, "info", {
        buttons: false,
        timer: 8000,
      });
    }
  }

  // when click withdraw button
  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };

  const columns = [
    {
      name: `${t("Partner ID")}`,
      selector: (row) => row.partner_id,
    },
    {
      name: `${t("Seller ID")}`,
      selector: (row) => row.seller_id,
    },
    {
      name: `${t("Product Name")}`,
      selector: (row) => row.product_name,
    },
    {
      name: `${t("Product Price")}`,
      selector: (row) => `${row.product_price} ₼`,
    },
    {
      name: `${t("Gold Bar Price")}`,
      selector: (row) => `${row.gold_bar_price} ₼`,
    },
    {
      name: `${t("Created At")}`,
      selector: (row) => row.created_at,
    },
    {
      name: `${t("Updated At")}`,
      selector: (row) => row.updated_at,
    },
    {
      name: `${t("Sales Mode")}`,
      selector: (row) => row.sales_mode,
    },
    {
      name: `${t("Cash Back")}`,
      // selector: (row) => row.cash_back,
      selector: (row) => {
        // const status = row.active_status;

        // css code available in buyer.css

        const cash_back =
          row.active_status == "1"
            ? ` ${
                (parseFloat(row.product_price) *
                  parseFloat(row.buyers_commition)) /
                100
              }`
            : row.active_status == "2"
            ? ` ${(row.product_price * 0) / 100}`
            : row.active_status == "3"
            ? ` ${(row.product_price * 0) / 100}`
            : "";

        return cash_back;
      },
    },
    {
      name: `${t("status")}`,
      selector: (row) => {
        const status = row.active_status;

        // css code available in buyer.css
        const styleStatus =
          row.active_status == "1"
            ? "Success"
            : row.active_status == "2"
            ? "Pending"
            : row.active_status == "3"
            ? "Cancel"
            : "";

        const styleStatusText =
          status == "1"
            ? "Success"
            : status == "2"
            ? "Pending"
            : status == "3"
            ? "Cancel"
            : "";

        return (
          <span className={`status ${styleStatus}`}>{styleStatusText}</span>
        );
      },
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const newData =
    data?.length > 0
      ? data?.map((items) => {
          const dataTable = {
            buyers_name: items.buyers_name,
            partner_id: items.partner_id,
            seller_id: items.seller_id,
            seller_name: items.seller_name,
            product_name: items.product_name,
            product_price: items.product_price,
            gold_bar_price: items.gold_bar_price,
            sales_mode: items.sales_mode,
            sales_source: items.sales_source,
            created_at: items.created_at,
            updated_at: items.updated_at,
            cash_back: ` ${
              (items.product_price * parseFloat(items.buyers_commition)) / 100
            }`,
            active_status: items.active_status,
          };

          return dataTable;
        })
      : null;

  let buyerData = null;

  if (isLoading) {
    buyerData = (
      <div className="bordered-shadow-box">
        <div className="bordered-shadow-box-overflow-hidden">
          <div className="seller-profile-sidebar">
            <div className="seller-profile-sidebar-collapsed-menu-area first">
              <div className="seller-profile-sidebar-menu collapse show">
                <ul>
                  <li>
                    {" "}
                    <span style={{ marginTop: "10px", marginLeft: "10px" }}>
                      Loading....
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!isLoading && buyerError) {
    buyerData = (
      <div className="bordered-shadow-box">
        <div className="bordered-shadow-box-overflow-hidden">
          <div className="seller-profile-sidebar">
            <div className="seller-profile-sidebar-collapsed-menu-area first">
              <div className="seller-profile-sidebar-menu collapse show">
                <ul>
                  <li>
                    <span style={{ marginTop: "10px", marginLeft: "10px" }}>
                      {buyerError} :)
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!isLoading && data?.length === 0) {
    buyerData = <h1>No Data Avaiable</h1>;
  } else if (!isLoading && data?.length > 0) {
    buyerData = (
      <div>
        <div>
          {/*-----     Summary Section ------ */}
          <div className="seller-transitions-summary-list">
            <div className="row" style={{ paddingTop: "0px" }}>
              <div className="col-lg-6">
                <div className="info-box">
                  <h2>{t(`Wallet Details`)}</h2>
                  <div className="row">
                    <div className="col-lg-4 col-4 left-item text-align-left">
                      <div className="align-items-flex-start">
                        <p>
                          {t("Hi")} {user.name}
                        </p>
                        <p>{t("You Have")}</p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            wordBreak: "break-all",
                          }}>
                          <h3 style={{ marginRight: "8px" }}>
                            {formatNumberWithCommas(data.length)}
                          </h3>
                          <p>{t("Transaction")} </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-5 col-5 right-item text-align-left">
                      <div className="align-items-flex-start">
                        <p>{t("Today Gold Bar Amount")}</p>
                        <p>
                          {GoldBarPrice <= 0
                            ? GoldBarPrice
                            : `${GoldBarPrice}  ₼`}
                        </p>
                        <p>{t("Your Earning")}</p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "15px",
                          }}>
                          <div
                            style={{
                              display: "inline-block",
                              content: "",
                              width: `${TotalCommition}%`,
                              height: "2px",
                              backgroundColor: "blue",
                              alignSelf: "center",
                            }}></div>
                        </div>

                        <p>
                          {TotalCommition <= 0
                            ? TotalCommition
                            : `${TotalCommition}  ₼`}
                        </p>

                        <p>{t("Avg Gold Bar Earning")} </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "15px",
                          }}>
                          <div
                            style={{
                              display: "inline-block",
                              content: "",
                              width: `${Comission}%`,
                              height: "2px",
                              backgroundColor: "blue",
                              alignSelf: "center",
                            }}></div>
                          <p
                            style={{
                              float: "left",
                              paddingLeft: "0",
                              marginLeft: "5px",
                              lineHeight: "20px",
                              textAlign: "left",
                            }}>
                            {parseFloat(
                              (TotalCommition / parseFloat(AvgGoldBarPrice)) *
                                100
                            ).toFixed(2)}{" "}
                            %
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-3 left-item text-align-left">
                      <img src={GolgImg} alt="logo" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-box">
                  <h2>{t(`TOTAL AVAILABLE GOLD BARS`)}</h2>
                  <div className="row align-items-center d-flex">
                    <div className="col-lg-8 left-item d-flex align-items-center flex-wrap justify-content-center">
                      <h1
                        style={{
                          fontSize: "14px",
                          marginTop: "0",
                          fontFamily: "sans-serif",
                          color: "#e6ba3f",
                          wordWrap: "break-word",
                          width: "100%",
                          fontWeight: "bold",
                          alignSelf: "center",
                        }}>
                        {t("Total Gold Bar")}{" "}
                        {parseInt(
                          ((TotalCommition / GoldBarPrice) * 100) / 100
                        ).toFixed(2)}
                      </h1>
                      <h6
                        style={{
                          fontSize: "14px",
                          marginTop: "0",
                          fontFamily: "sans-serif",
                          color: "#e6ba3f",
                          wordWrap: "break-word",
                          width: "100%",
                          fontWeight: "bold",
                          alignSelf: "center",
                        }}>
                        {t("Total Withdraw")} {Withdraw}
                      </h6>
                      <hr></hr>

                      <h1
                        style={{
                          fontSize: "15px",
                          marginTop: "0",
                          fontFamily: "sans-serif",
                          color: "green",
                          wordWrap: "break-word",
                          width: "100%",
                          alignSelf: "center",
                        }}>
                        {t("Available Gold Bar")}{" "}
                        {parseInt(
                          ((TotalCommition / parseFloat(AvgGoldBarPrice)) *
                            100) /
                            100 -
                            Withdraw
                        ).toFixed(2)}
                      </h1>
                    </div>

                    <div className="col-lg-2 right-item d-flex flex-wrap justify-content-center align-items-center">
                      <form onSubmit={(e) => handleSubmit(e)}>
                        {/* <input
                        type="hidden"
                        name="user_id"
                        value={user.user_id_info}
                        onChange={(e) => handleChange(e)}
                      />

                      <input
                        type="hidden"
                        name="amount"
                        value={parseInt(
                          ((TotalCommition / GoldBarPrice) * 100) / 100 -
                            Withdraw
                        ).toFixed(2)}
                        onChange={(e) => handleChange(e)}
                      /> */}

                        <div className="form-group">
                          <button
                            style={{
                              backgroundColor: "#3a5af9",
                              color: "#000",
                              padding: "10px 20px",
                              marginLeft: "1rem",
                              cursor: "pointer",
                              marginTop: "-5rem",
                            }}
                            type="submit"
                            className="seller-form-submit">
                            {t("WITHDRAWL")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ------- */}
          {/* -------  Transaction List ------ */}
          <div className="row seller-dashboard-col-equal">
            <div className="col-lg-12">
              <div className="seller-profile-panel">
                <div className="seller-profile-panel-header transaction">
                  Transaction History
                </div>
                <div className="seller-profile-panel-body">
                  <div className="table-responsive">
                    {
                      //   <DataTable
                      //   id="DataTableID-1234"
                      //   columns={columns}
                      //   data={newData}
                      // />
                    }

                    <DataTableExtensions {...tableData}>
                      <DataTable
                        columns={columns}
                        data={newData}
                        noHeader
                        defaultSortField="id"
                        sortIcon={<SortIcon />}
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        dense
                      />
                    </DataTableExtensions>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  -------------- */}
        </div>
      </div>
    );
  }
  // const buyerData =
  //   data?.length > 0 ? (
  //     <div>
  //       <div>
  //         {/*-----     Summary Section ------ */}
  //         <div className="seller-transitions-summary-list">
  //           <div className="row" style={{ paddingTop: "0px" }}>
  //             <div className="col-lg-6">
  //               <div className="info-box">
  //                 <h2>{t(`Wallet Details`)}</h2>
  //                 <div className="row">
  //                   <div className="col-lg-4 col-4 left-item text-align-left">
  //                     <div className="align-items-flex-start">
  //                       <p>
  //                         {t("Hi")} {user.name}
  //                       </p>
  //                       <p>{t("You Have")}</p>
  //                       <div
  //                         style={{
  //                           display: "flex",
  //                           alignItems: "center",
  //                           flexWrap: "wrap",
  //                           wordBreak: "break-all",
  //                         }}>
  //                         <h3 style={{ marginRight: "8px" }}>
  //                           {formatNumberWithCommas(data.length)}
  //                         </h3>
  //                         <p>{t("Transaction")} </p>
  //                       </div>
  //                     </div>
  //                   </div>

  //                   <div className="col-lg-5 col-5 right-item text-align-left">
  //                     <div className="align-items-flex-start">
  //                       <p>{t("Gold Bar Amount")}</p>
  //                       <p>
  //                         {GoldBarPrice <= 0
  //                           ? GoldBarPrice
  //                           : `${GoldBarPrice}  ₼`}
  //                       </p>
  //                       <p>{t("Your Earning")}</p>
  //                       <div
  //                         style={{
  //                           display: "flex",
  //                           flexDirection: "row",
  //                           marginLeft: "15px",
  //                         }}>
  //                         <div
  //                           style={{
  //                             display: "inline-block",
  //                             content: "",
  //                             width: `${TotalCommition}%`,
  //                             height: "2px",
  //                             backgroundColor: "green",
  //                             alignSelf: "center",
  //                           }}></div>
  //                       </div>

  //                       <p>
  //                         {TotalCommition <= 0
  //                           ? TotalCommition
  //                           : `${TotalCommition}  ₼`}
  //                       </p>

  //                       <p>{t("Gold Bar Earning")} </p>
  //                       <div
  //                         style={{
  //                           display: "flex",
  //                           flexDirection: "row",
  //                           marginLeft: "15px",
  //                         }}>
  //                         <div
  //                           style={{
  //                             display: "inline-block",
  //                             content: "",
  //                             width: `${Comission}%`,
  //                             height: "2px",
  //                             backgroundColor: "blue",
  //                             alignSelf: "center",
  //                           }}></div>
  //                         <p
  //                           style={{
  //                             float: "left",
  //                             paddingLeft: "0",
  //                             marginLeft: "5px",
  //                             lineHeight: "20px",
  //                             textAlign: "left",
  //                           }}>
  //                           {parseFloat(
  //                             (TotalCommition / GoldBarPrice) * 100
  //                           ).toFixed(2)}{" "}
  //                           %
  //                         </p>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="col-lg-3 col-3 left-item text-align-left">
  //                     <img src={GolgImg} alt="logo" />
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="col-lg-6">
  //               <div className="info-box">
  //                 <h2>{t(`TOTAL AVAILABLE GOLD BARS`)}</h2>
  //                 <div className="row align-items-center d-flex">
  //                   <div className="col-lg-8 left-item d-flex align-items-center flex-wrap justify-content-center">
  //                     <h1
  //                       style={{
  //                         fontSize: "14px",
  //                         marginTop: "0",
  //                         fontFamily: "sans-serif",
  //                         color: "#e6ba3f",
  //                         wordWrap: "break-word",
  //                         width: "100%",
  //                         fontWeight: "bold",
  //                         alignSelf: "center",
  //                       }}>
  //                       {t("Total Gold Bar")}{" "}
  //                       {parseInt(
  //                         ((TotalCommition / GoldBarPrice) * 100) / 100
  //                       ).toFixed(2)}
  //                     </h1>
  //                     <h6
  //                       style={{
  //                         fontSize: "14px",
  //                         marginTop: "0",
  //                         fontFamily: "sans-serif",
  //                         color: "#e6ba3f",
  //                         wordWrap: "break-word",
  //                         width: "100%",
  //                         fontWeight: "bold",
  //                         alignSelf: "center",
  //                       }}>
  //                       {t("Total Withdraw")} {Withdraw}
  //                     </h6>
  //                     <hr></hr>

  //                     <h1
  //                       style={{
  //                         fontSize: "15px",
  //                         marginTop: "0",
  //                         fontFamily: "sans-serif",
  //                         color: "green",
  //                         wordWrap: "break-word",
  //                         width: "100%",
  //                         alignSelf: "center",
  //                       }}>
  //                       {t("Available Gold Bar")}{" "}
  //                       {parseInt(
  //                         ((TotalCommition / GoldBarPrice) * 100) / 100 -
  //                           Withdraw
  //                       ).toFixed(2)}
  //                     </h1>
  //                   </div>

  //                   <div className="col-lg-2 right-item d-flex flex-wrap justify-content-center align-items-center">
  //                     <form onSubmit={(e) => handleSubmit(e)}>
  //                       {/* <input
  //                         type="hidden"
  //                         name="user_id"
  //                         value={user.user_id_info}
  //                         onChange={(e) => handleChange(e)}
  //                       />

  //                       <input
  //                         type="hidden"
  //                         name="amount"
  //                         value={parseInt(
  //                           ((TotalCommition / GoldBarPrice) * 100) / 100 -
  //                             Withdraw
  //                         ).toFixed(2)}
  //                         onChange={(e) => handleChange(e)}
  //                       /> */}

  //                       <div className="form-group">
  //                         <button
  //                           style={{
  //                             backgroundColor: "#3a5af9",
  //                             color: "#000",
  //                             padding: "10px 20px",
  //                             marginLeft: "1rem",
  //                             cursor: "pointer",
  //                             marginTop: "-5rem",
  //                           }}
  //                           type="submit"
  //                           className="seller-form-submit">
  //                           {t("WITHDRAWL")}
  //                         </button>
  //                       </div>
  //                     </form>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         {/* ------- */}
  //         {/* -------  Transaction List ------ */}
  //         <div className="row seller-dashboard-col-equal">
  //           <div className="col-lg-12">
  //             <div className="seller-profile-panel">
  //               <div className="seller-profile-panel-header transaction">
  //                 Transaction History
  //               </div>
  //               <div className="seller-profile-panel-body">
  //                 <div className="table-responsive">
  //                   {
  //                     //   <DataTable
  //                     //   id="DataTableID-1234"
  //                     //   columns={columns}
  //                     //   data={newData}
  //                     // />
  //                   }

  //                   <DataTableExtensions {...tableData}>
  //                     <DataTable
  //                       columns={columns}
  //                       data={newData}
  //                       noHeader
  //                       defaultSortField="id"
  //                       sortIcon={<SortIcon />}
  //                       defaultSortAsc={true}
  //                       pagination
  //                       highlightOnHover
  //                       dense
  //                     />
  //                   </DataTableExtensions>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         {/*  -------------- */}
  //       </div>
  //     </div>
  //   ) : (
  //     <p>Loading....</p>
  //   );

  // modal functionality
  const [errorAmount, setErrorAmount] = useState("");

  const handlerClick = async () => {
    if (
      parseFloat(inputNumber) < 0 ||
      !isNaN(Number(parseFloat(inputNumber))) === false
    ) {
      setErrorAmount("You Need to Give Valid Number");
      return;
    }

    const amount = parseInt(
      ((TotalCommition / GoldBarPrice) * 100) / 100 - Withdraw
    ).toFixed(2);

    const checkerAmount = parseFloat(inputNumber) <= parseFloat(amount);

    if (!checkerAmount) {
      setErrorAmount("You Have not Enough Amount!");
      return;
    }

    const FinalAmount =
      parseFloat(amount) - parseFloat(inputNumber) <= parseFloat(amount);

    if (!FinalAmount) return;

    try {
      const user_id = user.user_id_info;
      const account_mode = user.account_mode;

      const response = await formData({
        amount: parseFloat(inputNumber),
        account_mode,
        user_id,
      });

      // if (response?.status === true) {
      //   // Request was successful, handle the response data
      //   console.log("Request was successful:", response);

      // } else {
      //   // Handle other status codes (e.g., 404, 500, etc.)
      //   console.error("Request failed with status:", response?.status);
      // }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <div id="layout">
        <div className="main">
          <div>
            <div className="header-fixed-content-layout">
              <section className="seller-profile-area common-padding white-shaded-bg">
                <div className="container">
                  {open && (
                    <ModalDialog
                      setOpen={setOpen}
                      handlerClick={handlerClick}
                      setInputNumber={setInputNumber}
                      buttonText="WithDraw"
                      error={errorAmount}
                      setErrorAmount={setErrorAmount}
                    />
                  )}
                  <div className="row">
                    {/* ------  Sidebar ------- */}
                    {/* seller-profile-sidebar-col d-none d-xl-block */}
                    <div className="col-lg-3">
                      <div className="bordered-shadow-box">
                        <div className="bordered-shadow-box-overflow-hidden">
                          <div className="seller-profile-sidebar">
                            {/* ---main sidebar */}
                            <div className="seller-profile-sidebar-collapsed-menu-area first">
                              <a
                                className="seller-profile-sidebar-collapsed-menu-header"
                                data-toggle="collapse"
                                to="#profile-options"
                                role="button"
                                aria-expanded="true"
                                aria-controls="m-profile-options">
                                <span> {t("Profile Options")}</span>
                                <span className="icon">
                                  <i className="fas fa-chevron-right"></i>
                                </span>
                              </a>
                              <div
                                id="profile-options"
                                className="seller-profile-sidebar-menu collapse show">
                                <ul>
                                  {/* <li>
                                    <Link to="/buyer-dashboard">
                                      <span className="icon">
                                        {" "}
                                        <img src={DashboardImg} alt="" />{" "}
                                      </span>
                                      <span className="text">Dashboard</span>
                                    </Link>
                                  </li> */}

                                  <li>
                                    <Link to="/buyer-transitions">
                                      <span className="icon">
                                        {" "}
                                        <img src={OrdersImg} alt="" />{" "}
                                      </span>
                                      <span className="text">
                                        {t("Transaction")}
                                      </span>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {/*----------- */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------------ */}

                    {/*---------  Main Contents ------- */}
                    {/* seller-profile-content-col */}
                    <div className="col-lg-9">
                      <div className="seller-profile-content-area">
                        {/*---- Header element ---- */}
                        <div className="seller-profile-content-header">
                          <h3 style={{ color: "black", marginTop: "0.625em" }}>
                            {t("Buyer Transaction")}
                          </h3>

                          <div className="seller-profile-content-breadcumb">
                            <ul>
                              <li>
                                <a to="/">{t("Home")}</a>
                              </li>
                              <li>
                                <a to="/">{t("Transaction")}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/*  --------- */}

                        {/* ---- Other elements ---- */}
                        {buyerData}
                        {/* ----- */}
                      </div>
                    </div>
                    {/* -------------------------- */}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
