import "./Button.css";

const CardButton = ({
  ProductQuantities,
  productId = "",
  selectedQunatities,
  handler = () => {},
}) => {
  const ItemRender = ProductQuantities.map(({ id, quantity }) => {
    const itemId = `${id}${productId}`;

    return (
      <div
        key={itemId}
        className={
          selectedQunatities === itemId || selectedQunatities === quantity
            ? "cardbutton-design cardbutton-active"
            : "cardbutton-design cardbutton-seleted"
        }
        onClick={() => handler({ productId, quantityId: id, quantity })}>
        {quantity}
      </div>
    );
  });

  return <div className="cardbutton-main">{ItemRender}</div>;
};

export default CardButton;
