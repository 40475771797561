import React from "react";
import "./Sponsor.css";
import Img from "../../../assets/Emirates.png";
import Img1 from "../../../assets/RGB.png";
import Img2 from "../../../assets/yelobank.png";
import Img3 from "../../../assets/logo-Pasha.png";
import Img4 from "../../../assets/FMG.png";
import Img5 from "../../../assets/NadirGold-.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function SponsorSection() {
  const options = {
    loop: true,
    // center: true,
    items: 6,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,

    nav: false,
  };

  return (
    <>
      <section className="sponsor-area light-bg pt-70 pb-70">
        <div className="container extraContainer">
          <div className="row">
            <div className="col-lg-12">
              <div className="sponsor-slider-one slick-initialized slick-slider">
                <OwlCarousel
                  className="owl-theme"
                  // loop
                  // margin={10}
                  // nav
                  items={6}
                  dots
                  mouseDrag={false}
                  stagePadding={0}
                  key={5}
                  {...options}>
                  <img
                    data-src={Img}
                    className="lazy entered loaded"
                    alt="sponsors"
                    data-ll-status="loaded"
                    src={Img}
                    style={{ width: "70%" }}
                  />

                  <img
                    data-src={Img1}
                    className="lazy entered loaded"
                    alt="sponsors"
                    data-ll-status="loaded"
                    src={Img1}
                    style={{ width: "70%" }}
                  />

                  <img
                    data-src={Img2}
                    className="lazy entered loaded"
                    alt="sponsors"
                    data-ll-status="loaded"
                    src={Img2}
                    style={{ width: "70%" }}
                  />

                  <img
                    data-src={Img3}
                    className="lazy entered loaded"
                    alt="sponsors"
                    data-ll-status="loaded"
                    src={Img3}
                    style={{ width: "70%" }}
                  />

                  <img
                    data-src={Img4}
                    className="lazy entered loaded"
                    alt="sponsors"
                    data-ll-status="loaded"
                    src={Img4}
                    style={{ width: "70%" }}
                  />

                  <img
                    data-src={Img5}
                    className="lazy entered loaded"
                    alt="sponsors"
                    data-ll-status="loaded"
                    style={{ width: "70%" }}
                    src={Img5}
                  />
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
