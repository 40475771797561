import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import GetTransation from "./GetTranslation";

const defaultLang = localStorage.getItem("lang") || "az";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        welcome: "Welcome!",
      },
    },
  },
  lng: defaultLang,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  debug: false,
});

GetTransation().then((translations) => {
  for (let lang in translations) {
    const namespaces = translations[lang];

    for (let namespace in namespaces) {
      const resources = namespaces[namespace];

      i18n.addResourceBundle(lang, namespace, resources, true, true);
    }
  }

  i18n.changeLanguage(defaultLang);
});

export default i18n;
