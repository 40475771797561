import { useState } from "react";
import DesginGoldBarForm from "./DesginGoldBarForm";

import Swal from "sweetalert2";
import DesginLeftImage from "./DesginLeftImage";
import DesingRightImage from "./DesingRightImage";
import { AddFormData } from "./AddFormData";

// this is condition for seleted size to show image or hide image
const selectedSize = ["0.10", "0.1", "0.25", "0.5"];
const selectedSizeRight = ["1"];

const DesgingGoldbar = () => {
  const [rightSidezoomedImage, setRightSideZoomedImage] = useState(null);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectedQunatities, setselectedQunatities] = useState("");
  const [loader, setLoader] = useState(false);

  const handlerOrder = async (params) => {
    const { name, phoneNumber, size, price } = params || {};
    // name && phoneNumber && files.length > 0
    if (name && phoneNumber && size && !isNaN(price) && files.length > 0) {
      let chooseImage = [];
      // when quantity change the image array will be updated
      // ["0.10", "0.25", "0.5"] left side  | ["1"] right;
      if (selectedSizeRight.includes(selectedQunatities)) {
        chooseImage.push(zoomedImage);
      } else if (selectedSize.includes(selectedQunatities)) {
        chooseImage.push(rightSidezoomedImage);
      } else {
        chooseImage = [];
      }
      if (chooseImage.length > 0) {
        const Data = {
          name: name,
          number: phoneNumber,
          price: price,
          size: size,
          image: chooseImage,
        };

        try {
          setLoader(true);
          await AddFormData(Data);
          setLoader(false);
        } catch (error) {
          setLoader(false);
          console.log("Error Occure In Desgin Gold Bar");
        }
      } else {
        Swal.fire({
          title: "",
          text: "Image is Not save Please Recheck the quantity and Save Details Again",
          icon: "info",
        });
      }
    } else {
      Swal.fire({
        title: "",
        text: "You Need To Give Details For Order",
        icon: "info",
      });
      return;
    }
  };

  return (
    <>
      <section
        className=" section-header-part breadcrumb-section bg-img-c lazy entered loaded"
        data-ll-status="loaded"></section>
      <div className="project-section desgin-part mt-0 mt-lg-4">
        <div className="container ">
          <div className="row">
            <div className="col-12 order-2 order-md-1 col-lg-3">
              {selectedSize.includes(selectedQunatities) ? (
                ""
              ) : (
                <>
                  <DesginLeftImage
                    files={files}
                    setZoomedImage={setZoomedImage}
                    zoomedImage={zoomedImage}
                    selectedQunatities={selectedQunatities}
                    setselectedQunatities={setselectedQunatities}
                    selectedSize={selectedSize}
                  />
                </>
              )}
            </div>
            <div className="col-12 order-1 order-lg-2 col-lg-6 d-flex">
              <div className="w-100 mt-0 mt-md-4">
                <DesginGoldBarForm
                  loader={loader}
                  handleOrder={handlerOrder}
                  setFiles={setFiles}
                  setselectedQunatities={setselectedQunatities}
                  selectedQunatities={selectedQunatities}
                  setRightSideImage={setRightSideZoomedImage}
                  setLeftSideImage={setZoomedImage}
                />
              </div>
            </div>
            <div className="col-12 order-3 col-lg-3  col-lg-3">
              {selectedSizeRight.includes(selectedQunatities) ? (
                ""
              ) : (
                <>
                  <DesingRightImage
                    rightSidezoomedImage={rightSidezoomedImage}
                    files={files}
                    setRightSideZoomedImage={setRightSideZoomedImage}
                    selectedQunatities={selectedQunatities}
                    setselectedQunatities={setselectedQunatities}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesgingGoldbar;
