import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./News.css";
import PopularAndTrandingNews from "./PopularAndTrandingNews";

const News = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n?.language || "en";

  const [featureNews, setFeatureNews] = useState(null);
  const [LatestNews, setLatestNews] = useState(null);
  const [searchText, setSearchText] = useState("");

  const handlerSearch = (e) => {
    setSearchText(e.target.value);
  };

  // for load more data
  const [visible, setVisible] = useState(8);

  const loadMore = () => {
    setVisible((prev) => parseInt(prev) + 4);
  };

  // for fealtering all diffrent features
  const [TrendingNews, setTrendingNews] = useState(null);
  const [PopularNews, setPopularNews] = useState(null);
  const [ExclusiveNews, setExclusiveNews] = useState(null);
  // Filter result
  const [searchResult, setSearchResult] = useState([]);

  const [otherNews, setOtherNews] = useState(null);

  const apiGetfeatureNews = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_Url}/news`);
      const jsondata = await response.json();

      const newsData = jsondata.news.map((item) => {
        return {
          ...item,
          en_description: item.description,
          en_tag: item.description,
          en_title: item.title,
        };
      });

      setFeatureNews(newsData);
      //filter by exclusive latest popular status
      newsData?.filter((item) => {
        if (item.latest) {
          setTrendingNews((prev) => {
            if (prev?.length > 0) {
              return [...prev, item];
            } else {
              return [item];
            }
          });
        }
        if (item.popular) {
          setPopularNews((prev) => {
            if (prev?.length > 0) {
              return [...prev, item];
            } else {
              return [item];
            }
          });
        }
        if (item.exclusive) {
          setExclusiveNews((prev) => {
            if (prev?.length > 0) {
              return [...prev, item];
            } else {
              return [item];
            }
          });
        }
        if (item.exclusive === 0 && item.latest === 0 && item.popular === 0) {
          setOtherNews((prev) => {
            if (prev?.length > 0) {
              return [...prev, item];
            } else {
              return [item];
            }
          });
        }
      });
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  const apiGetFeatureLatestNews = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_Url}/latestNews`);
      const jsondata = await response.json();
      // console.log(jsondata.latest_news);

      const newsData = jsondata.latest_news.map((item) => {
        return {
          ...item,
          en_name: item.name,
        };
      });

      setLatestNews(newsData);
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  useEffect(() => {
    apiGetfeatureNews();
    apiGetFeatureLatestNews();
  }, []);

  // get filter search
  useEffect(() => {
    if (searchText.trim() !== "") {
      searchParam();
    }
  }, [searchText]);

  async function searchParam() {
    const response = await fetch(
      `https://eastgold.az/admin/api/newsSearch?search=${searchText}`
    );
    const jsondata = await response.json();

    // filter the search
    setSearchResult(jsondata?.message);
  }

  // for marquee features\ [null , null , null , null] -> false
  const filterLatestNewsRender = LatestNews?.every((item) => {
    const name = item[`${lang}_name`];
    return name === null;
  });

  const LatestNewsRender =
    !filterLatestNewsRender &&
    LatestNews?.map((item) => {
      const name = item[`${lang}_name`] || "";
      if (name === null) {
        return "";
      } else {
        return name + " ";
      }
    }).join(" ");

  const ListForNewsArticle = ExclusiveNews?.slice(3, visible)?.map(
    (item, index) => {
      const { id, created_by, image } = item || {};
      const leadArticle =
        index > 0 && index < 4 && index % 2 === 0 ? "lead" : "";
      const title = item[`${lang}_title`] || "";
      const description = item[`${lang}_description`] || "";

      return (
        <article key={id} className={`article ${leadArticle}`}>
          <Link to={`/news/${id}`} style={{textDecoration:'none'}}>
          <figure className="img">
            <img
              src={
                `${process.env.REACT_APP_IMAGE_URL}/admin/assets/images/news/` +
                image
              }
              height="540"
              width="960"
              alt=""
            />
          </figure>
          
          <h2 className="title">
            {title}
          </h2>

          {index === 2 ? <p className="teaser">{description}</p> : ""}

          <div
            className="article-meta"
            style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="article-date">
              <i className="icon fa-regular fa-calendar" aria-hidden="true"></i>
              <time>4 april 2023</time>
            </p>
            <p className="article-date">
              <i className="fa-solid fa-at"></i>
              <time className="ps-2">{created_by}</time>
            </p>
          </div>
          </Link>
        </article>
      );
    }
  );

  const FilterForNewsArticle =
    searchResult?.length > 0 ? (
      searchResult?.slice(0, visible)?.map((item, index) => {
        const { id, created_by, image } = item || {};
        const leadArticle =
          index > 0 && index < 4 && index % 2 === 0 ? "lead" : "";
        const title = item[`${lang}_title`] || "";
        const description = item[`${lang}_description`] || "";

        return (
          <article key={id} className={`article ${leadArticle}`}>
            <div className="col-md-3">
              <img src={`${process.env.REACT_APP_IMAGE_URL}/admin/assets/images/news/`+image}/>
      

            <h2 className="title">
              <Link to={`/news/${id}`} >{title}</Link>
            </h2>

            {index === 2 ? <p className="teaser">{description}</p> : ""}

            <div
              className="article-meta"
              style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="article-date">
                <i
                  className="icon fa-regular fa-calendar"
                  aria-hidden="true"></i>
                <time>4 april 2023</time>
              </p>
              <p className="article-date">
                <i className="fa-solid fa-at"></i>
                <time className="ps-2">{created_by}</time>
              </p>
            </div>
            </div>
          </article>
        );
      })
    ) : (
      <h1>No Data Found</h1>
    );
  // px-0 px-md-5
  return (
    <section className="container  news-section header-top">
      <div style={{ position: "relative" }}>
        {LatestNews !== null && !filterLatestNewsRender && (
          <>
            <div className="text-black latest-polygone marquee-desgin">
              Latest News
            </div>
            <marquee
              behaviour="scroll"
              direction="left"
              className="text-white top-hotel-feature"
              style={{ border: "1px solid #fff", padding: "7px" }}>
              {LatestNewsRender}
            </marquee>
          </>
        )}
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              {/* <div className="extraLayoutFeachers"></div> */}
              {ExclusiveNews?.slice(0, 2)?.map((item, index) => (
                <div className="mt-4" style={{ position: "relative" }}>
                  <div className="extraLayoutFeachers"></div>
                  <Link to={`/news/${item.id}`}>
                    <img
                      className="w-100"
                      src={
                        `${process.env.REACT_APP_IMAGE_URL}/admin/assets/images/news/` +
                        item?.image
                      }
                    />
                    <div className="contentNews">
                    <h5>{item.title}</h5>
                      <small style={{color:'white'}}>
                        {new Date(item?.publishing_time).toLocaleDateString()} 
                        {/* {new Date(item?.publishing_time).getFullYear()} */}
                      </small>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <div className="col-md-6">
              {/* <div className="extraLayoutFeachers"></div> */}
              {ExclusiveNews?.slice(2, 3)?.map((item, index) => (
                <div className="mt-4 " style={{ position: "relative" }}>
                  <div className="extraLayoutFeachers"></div>
                  <Link to={`/news/${item.id}`}>
                    <img
                      className="w-100"
                      src={
                        `${process.env.REACT_APP_IMAGE_URL}/admin/assets/images/news/` +
                        item?.image
                      }
                    />
                    <div className="contentNews">
                    <h5>{item.title}</h5>
                      <small style={{color:'white'}}>
                        {new Date(item?.publishing_time).toLocaleDateString()} 
                        {/* {new Date(item?.publishing_time).getFullYear()} */}
                      </small>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <div className="col-md-3 mt-4">
              <form className="d-flex border mb-2" role="search">
                <input
                  className="form-control text-white"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  style={{
                    background: "transparent",
                    border: "transparent",
                  }}
                  onChange={handlerSearch}
                />
                <div
                  className="d-flex align-items-center"
                  style={{
                    background: "transparent",
                  }}>
                  <i className="pr-2 fa-solid fa-magnifying-glass text-white"></i>
                </div>
              </form>

              <PopularAndTrandingNews />
            </div>
          </div>
        </div>
      </div>
      {/* feature grid news */}

      <div
        className="list-articles news-list-articels"
        style={{ marginTop: "20px" }}>
        {searchText.trim() === "" ? (
          <>{ListForNewsArticle}</>
        ) : (
          <>{FilterForNewsArticle}</>
        )}
      </div>

      <div className="mt-5 text-center">
        {!searchText &&
          ExclusiveNews?.length > 0 &&
          visible < ExclusiveNews?.length && (
            <Button
              onClick={loadMore}
              className="px-3 py-3 text-white border border-light">
              See More News
            </Button>
          )}
        {searchText &&
          searchResult?.length > 0 &&
          visible < searchResult?.length && (
            <Button
              onClick={loadMore}
              className="px-3 py-3 text-white border border-light">
              See More News
            </Button>
          )}
      </div>
    </section>
  );
};

export default News;
