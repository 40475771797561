import React, { useState } from "react";
import "./Contact.css";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

async function formData(credentials) {
  const csrfToken = document.head
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute("content");

  const response = await fetch(`${process.env.REACT_APP_Url}/contactMail`, {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },

    body: JSON.stringify(credentials),
  });

  const result = await response.json();

  return result;
}

const ContactForm = () => {
  const { t, i18n } = useTranslation();

  const [contactinfo, setContactInfo] = useState({
    fullname: "",
    email: "",
    subject: "",
    message: "",
  });

  const contactChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlerContact = async (e) => {
    e.preventDefault();

    const { fullname: name, email, subject, message } = contactinfo || {};

    if (
      name &&
      name.trim() !== "" &&
      email &&
      email.trim() !== "" &&
      subject &&
      subject.trim() !== "" &&
      message &&
      message.trim() !== ""
    ) {
      const { status, message: contactMessage } =
        (await formData({
          name,
          email,
          subject,
          message,
        })) || {};

      if (status) {
        Swal.fire({
          title: "Successfully Message sent",
          icon: "success",
        });
      } else {
        Swal.fire({
          title: contactMessage || "something went wrong",
          icon: "error",
        });
      }
      setContactInfo({ fullname: "", email: "", subject: "", message: "" });
    } else {
      Swal.fire({
        title: "Please Give Your Information",
        icon: "error",
      });
    }
  };

  return (
    <form onSubmit={handlerContact}>
      <input
        type="hidden"
        name="_token"
        value="Pe3TTp0JCxj8C4nWmz90tf1Ph0IPeLUxMg3b1WI0"
      />{" "}
      <input type="hidden" name="id" value="174" />
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="form_group">
            <input
              type="text"
              className="form_control"
              placeholder={t("Name")}
              name="fullname"
              required=""
              onChange={contactChange}
              value={contactinfo.fullname}
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="form_group">
            <input
              type="email"
              className="form_control"
              placeholder={t("Email Address")}
              name="email"
              required=""
              onChange={contactChange}
              value={contactinfo.email}
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="form_group">
            <input
              type="text"
              className="form_control"
              placeholder={t("Subject")}
              name="subject"
              required=""
              onChange={contactChange}
              value={contactinfo.subject}
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="form_group">
            <textarea
              className="form_control"
              placeholder={t("Message")}
              onChange={contactChange}
              name="message"
              value={contactinfo.message}></textarea>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="form_group">
            <button type="submit" className="arrow-btn main-btn">
              {t("Send Message")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
