import React from "react";
import "./Contact.css";
import { useTranslation } from "react-i18next";
import ContactForm from "./ContactForm";

export default function ContactSection() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <section className="contact-area light-bg pb-130 pt-0">
        <div className="container">
          <div
            className="contact-wrapper-one wow fadeInDown"
            data-wow-delay=".2s"
            style={{
              visibility: "visible",
              animationDelay: "0.2s",
              animationName: "fadeInDown",
            }}>
            <div className="contact-form">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
