import React, { useEffect, useState } from 'react'
import './BuyBack.css'
import tableLogo from './img/Capture.PNG'
import { FaEuroSign } from "react-icons/fa6";


function BuyBack() {
  const [buybackData,setBuybackData]=useState([])

  useEffect(()=>{
    const fetchingData = async()=>{
      const res = await fetch(`https://eastgold.az/admin/api/buyback`)
      const resData = await res.json()
      setBuybackData(resData)
    }
    fetchingData()
  },[])

console.log(process.env.REACT_APP_IMAGE_URL+buybackData[0]?.images[0].image)//buybackData[0]?.images[0].image
  return (
    <div style={{marginTop:'100px'}}>
        <div className="container">
            <div style={{display:'flex',justifyContent:"space-between"}}>
                <h5 className='buyBackName' style={{color:'#87643b',textTransform:'uppercase'}}>Buy Back</h5>
                <h5 style={{color:'#87643b',textTransform:'uppercase'}}>Update Date:21.02.2024</h5>
            </div>
            <table style={{ borderCollapse: 'collapse', width: '100%' ,border:'2px solid #B5987F'}}>
        <thead>
          <tr style={{border:'2px solid #B5987F'}}>
            <th style={{ padding: '2px 8px', textAlign: 'left',textTransform:'uppercase' }}>Name</th>
            <th style={{ padding: '2px 8px', textAlign: 'center',textTransform:'uppercase' }}>Size</th>
            <th style={{ padding: '2px 8px', textAlign: 'right',textTransform:'uppercase' }}>Price</th>
          </tr>
        </thead>
        <tbody>
          {
            buybackData?.map((item,index)=> {
              // console.log(`${process.env.REACT_APP_IMAGE_URL}${item?.images[1]?.image}`)
              
            return  <tr style={{border:'2px solid #B5987F'}}>
            <td style={{ padding: '8px',textAlign: 'left',textTransform:'uppercase' }}>
              <img style={{width:'60px',height:'60px'}} src={`${process.env.REACT_APP_IMAGE_URL}${item?.images[0]?.image}`}/>
              </td>
            <td style={{ padding: '8px',textAlign: 'center',textTransform:'uppercase' }}>{item.size}</td>
            <td style={{ padding: '8px',textAlign: 'right',textTransform:'uppercase' }}>{item?.price}<FaEuroSign style={{transform: 'rotate(90deg)'}} /></td>
          </tr>
            })
          }
        </tbody>
      </table>
        </div>
    </div>
  )
}

export default BuyBack