import React from "react";
import "./Team.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import HomePageTable from "../../../components/HomePageTable/HomePageTable";

const teamMemberData = [
  {
    Teamimage: "./teamMemberImage/1.jpeg",
    Teamname: "Rufat Shabanov",
    Teamposition: "Marketing manager",
    Teamlink: [],
  },
  {
    Teamimage: "./teamMemberImage/2.jpeg",
    Teamname: "Ganima Abdurakhmanova",
    Teamposition: "Manager of electronic sales channels",
    Teamlink: [],
  },
  {
    Teamimage: "./teamMemberImage/4.jpeg",
    Teamname: "Teoman Kaplan",
    Teamposition: "Event manager",
    Teamlink: [],
  },
];

export default function TeamSection() {
  const { t, i18n } = useTranslation();

  const teamInfoRender = teamMemberData.map((item, index) => {
    const {
      Teamimage: image,
      Teamname: name,
      Teamposition: position,
      Teamlink: link,
    } = item || {};

    return (
      <div
        key={index}
        className="col-lg-4 col-md-6 col-sm-12 team-member-details-center">
        <div
          className="team-item team-item-one mb-40 wow fadeInUp"
          data-wow-delay=".25s"
          style={{
            visibility: "visible",
            animationDelay: "0.25s",
            animationName: "fadeInUp",
          }}>
          <div className="team-img">
            <img
              data-src={image}
              className="lazy entered loaded"
              alt="Team"
              data-ll-status="loaded"
              src={image}
            />
            <div className="team-overlay">
              <div className="team-social">
                <ul className="social-link">
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="team-content">
            <h3 className="title text-center">
              <a href="attorney-details.html">{t(name)}</a>
            </h3>
            <span className="position">{t(position)}</span>
          </div>
        </div>
      </div>
    );
  });
  return (
    <>
      <section className="team-area pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div
                className="section-title section-title-left mb-65 wow fadeInLeft"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInLeft",
                }}>
                <span className="sub-title">{t("Our Team")}</span>
              </div>
            </div>
          </div>
          <div className="row">{teamInfoRender}</div>
        </div>
      </section>
      {/* Buy Back Price For 21.02.2024 */}
      <div className="container ">
        <HomePageTable />
      </div>
    </>
  );
}
