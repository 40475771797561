export function nameToStarConverter(name) {
  const nameLength = name.length;

  const lastIndex = 5;
  const rest = nameLength - lastIndex <= 0 ? 0 : 6;
  const starConvert = Array(rest).fill("*").join("") || "";

  const nameWithStar = name.slice(0, lastIndex) + starConvert;

  return nameWithStar;
}
