import React from "react";
import "./Footer.css";
// import { Translation } from "react-i18next";
import { useTranslation } from "react-i18next";
// import ChangeLang from "../ChangeLang";
import LogoImg2 from "../../assets/mainlogo.svg";
// import FooterLogo from "../../assets/footer-logo.png";
// import { Link } from "react-router-dom";

const contact_Number = process.env.REACT_APP_CONTACT_NUMBER || "";

export default function Footer() {
  const { t } = useTranslation();

  // const externalImage = LogoImg;
  const [position, setPosition] = React.useState({ top: 0, left: 0 });
  React.useEffect(() => {
    window.scroll({
      top: position.top,
      left: position.left,
      behavior: "smooth",
    });
  });

  const [visibility, setVisibility] = React.useState(false);
  const scrollTop = React.useRef();
  React.useEffect(() => {
    window.addEventListener("scroll", (e) => {
      window.scrollY > 200
        ? (scrollTop.current.style.display = "inline-block")
        : (scrollTop.current.style.display = "none");
    });
  });

  return (
    <>
      <footer className="footer-area">
        <div
          data-bg={LogoImg2}
          className="footer-wrapper-one lazy position-relative bg_cover pb-30 entered loaded"
          data-ll-status="loaded"
          // style={{ backgroundImage: `url(${externalImage})` }}
        >
          <div className="container">
            <div className="footer-widget pt-47 ">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="widget about-widget mb-55 wow fadeInUp"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      textAlign: "center",
                    }}>
                    <a className="footer-logo" href="\">
                      <img src={LogoImg2} alt="logo" />
                    </a>

                    {
                      // you can also write logo here
                    }
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="widget footer-nav-widget mb-55 wow fadeInUp"
                    data-wow-delay=".3s"
                    style={{ visibility: "visible", animationDelay: "0.3s" }}>
                    <h4 className="widget-title">{t("Quick Links")}</h4>
                    <ul className="widget-link">
                      <li>
                        <a href="/desgin">{t("Create your gold bar")}</a>
                      </li>
                      <li>
                        <a href="#about">{t("About Us")}</a>
                      </li>
                      <li>
                        <a href="#">{t("Terms & Conditions")}</a>
                      </li>
                      <li>
                        <a href="#">{t("Privacy Policy")}</a>
                      </li>
                      <li>
                        <a href="/contact">{t("Contact Us")}</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="widget contact-info-widget mb-55 wow fadeInUp"
                    data-wow-delay=".5s"
                    style={{ visibility: "visible", animationDelay: "0.5s" }}>
                    <h4 className="widget-title">{t("Contact Us")}</h4>

                    <div className="info-widget-content mb-10">
                      <p>
                        <i className="fa-solid fa-phone"></i>
                        <a href={`tel: ${contact_Number}`}>{contact_Number}</a>
                      </p>
                      <p>
                        <i className="fa-regular fa-envelope"></i>
                        <a href="mailto: office@eastgold.az">
                          office@eastgold.az
                        </a>
                      </p>
                      <p>
                        <i className="fa-solid fa-location-dot"></i>
                        <span>
                          {" "}
                          {t("Karabakh Street, 15 Khatai Ave, Baku")}
                        </span>
                      </p>
                    </div>

                    {
                      //logo
                    }
                    <div className="share">
                      <ul className="social-link">
                        <li>
                          <a href="\">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="\">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="\">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a href="\">
                            <i className="fab fa-dribbble"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-behance"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-copyright">
              <div className="row">
                <div className="col-lg-12">
                  <div className="copyright-text text-center">
                    <p></p>
                    <p>
                      {t(`Copyright © 2023. All rights reserved by`)}{" "}
                      <a target="_blank" href="www.webcodecare.com">
                        www.webcodecare.com
                      </a>
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span
            onClick={() =>
              setPosition({ ...position, position: { top: 0, left: 0 } })
            }
            ref={scrollTop}
            className="back-to-top"
            style={{ display: "inline-block" }}>
            <i className="fas fa-angle-up"></i>
          </span>
        </div>
      </footer>
    </>
  );
}
