import "./PreLoaderCard.css";

export default function PreLoaderCard() {
  const preloadContent = Array.from(Array(3), (x, i) => {
    return (
      <div className="wrapper--div" key={i} style={{ marginBottom: "20px" }}>
        <div className="card-loader card-loader--tabs"></div>
      </div>
    );
  });

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>{preloadContent}</div>
  );
}
