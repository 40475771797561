import React, { useEffect } from "react";
import "./Seller.css";
// import DashboardImg from "../../assets/dashboard.png";
import ProfileImg from "../../assets/profile.png";
import OrdersImg from "../../assets/my-orders.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SellingHeadersPart, SellingInformationOption } from "./sellingContent";
import { nameToStarConverter } from "../../utils/nameToStarConvert";

async function formData(credentials) {
  const csrfToken = document.head
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute("content");

  const response = await fetch(`${process.env.REACT_APP_Url}/seller_product`, {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(credentials),
  }).then((data) => {
    return data.json();
  });

  if (response) {
    Swal.fire("Successfully!", response.message, "success", {
      buttons: false,
      timer: 2000,
    });

    window.location.reload();
  } else {
    Swal.fire("Error", response.message, "error");
  }
}

export default function Profile(props) {
  const { t, i18n } = useTranslation();

  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsPartner, setSuggestionsPartner] = useState([]);
  const [noMatch, setNoMatch] = useState(false);
  const [noPartnerMatch, setNoPartnerMatch] = useState(false);
  const gold_price = localStorage.getItem("gold_bar_price");

  const user = JSON.parse(localStorage.getItem("user"));
  // alert(user.data.user_id_info);

  // console.log(props);
  const [elements, setElements] = useState({
    product_name: "",
    product_price: "",
    buyer_id: "",
    partner_id: "",
    seller_id: "",
    sales_mode: "",
    sales_source: "",
    gold_bar_price: "",
  });

  const buyer = props.userBuyerData[0];
  const partner = props.userPartnerData[0];
  let product_name = elements.product_name;
  let product_price = elements.product_price;
  let buyer_id = elements.buyer_id;
  let partner_id = elements.partner_id;
  let seller_id = user.user_id_info;
  let sales_mode = elements.sales_mode;
  let sales_source = elements.sales_source;
  let gold_bar_price = gold_price;
  let history = useHistory();

  const partnerData = partner.map((items) => {
    return items;
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      history.push("/login");
    }
    setElements({ ...elements, buyer_id: buyer[0].buyer_id });

    setElements({ ...elements, partner_id: partnerData[0].partner_id });
    setElements({ ...elements, seller_id: seller_id });
  }, []);

  const handleBuyerfilter = (e) => {
    const inputValue = e.target.value;
    setElements({ ...elements, buyer_id: inputValue });
    const filteredBuyers = buyer.filter((buyer) =>
      buyer.buyer_id.toLowerCase().includes(inputValue.toLowerCase())
    );
    if (filteredBuyers.length === 0) {
      setNoMatch(true);
    } else {
      setNoMatch(false);
    }
    setSuggestions(filteredBuyers);
  };

  //console.log(suggestions);
  const handleBuyerSuggestionClick = (selectedBuyerId) => {
    setElements({ ...elements, buyer_id: selectedBuyerId });
    setSuggestions([]);
  };

  const handlePartnerfilter = (e) => {
    const inputValue = e.target.value;
    setElements({ ...elements, partner_id: inputValue });
    const filteredPartners = partner.filter((partner) =>
      partner.partner_id.toLowerCase().includes(inputValue.toLowerCase())
    );
    if (filteredPartners.length === 0) {
      setNoPartnerMatch(true);
    } else {
      setNoPartnerMatch(false);
    }
    setSuggestionsPartner(filteredPartners);
  };

  const handlePartnerSuggestionClick = (selectedPartnerId) => {
    setElements({ ...elements, partner_id: selectedPartnerId });
    setSuggestionsPartner([]);
  };

  const handleChange = (e) => {
    setElements({ ...elements, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await formData({
        product_name,
        product_price,
        buyer_id,
        partner_id,
        seller_id,
        sales_mode,
        sales_source,
        gold_bar_price,
      });

      if (response.status === 200) {
        // Request was successful, handle the response data
        console.log("Request was successful:", response);
      } else {
        // Handle other status codes (e.g., 404, 500, etc.)
        console.error("Request failed with status:", response.status);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <div id="layout">
        <div className="main">
          <div>
            <div className="header-fixed-content-layout">
              <section className="seller-profile-area common-padding white-shaded-bg">
                <div className="container">
                  <div className="row">
                    {/* ------  Sidebar ------- */}
                    <div className="col-lg-3 seller-profile-sidebar-col d-none d-xl-block">
                      <SellingInformationOption />
                    </div>
                    {/* ------------ */}

                    {/*---------  Main Contents ------- */}
                    <div className="col-lg-9 seller-profile-content-col">
                      <div className="seller-profile-content-area">
                        {/*---- Header element ---- */}
                        <SellingHeadersPart />
                        {/*  --------- */}

                        {/* ---- Other elements ---- */}
                        <div>
                          <div>
                            {/* ------ Profile Form ---- */}
                            <div className="seller-profile-form">
                              <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="seller-profile-panel">
                                  <div className="seller-profile-panel-header">
                                    {t("Basic Info")}
                                  </div>
                                  <div className="seller-profile-panel-body">
                                    <div className="form-group undefined">
                                      <div className="row align-items-center">
                                        <div className="col-sm-2">
                                          <label htmlFor="product_name">
                                            {t("Product Name")}
                                          </label>
                                        </div>
                                        <div className="col-sm-10">
                                          <div>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={t("Name")}
                                              name="product_name"
                                              id="product_name"
                                              onChange={(e) => handleChange(e)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group undefined">
                                      <div className="row align-items-center">
                                        <div className="col-sm-2">
                                          <label htmlFor="product_price">
                                            {t("Product Price")}
                                          </label>
                                        </div>
                                        <div className="col-sm-10">
                                          <div>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={t("Product Price")}
                                              name="product_price"
                                              id="product_price"
                                              onChange={(e) => handleChange(e)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="row align-items-center">
                                        <div className="col-sm-2">
                                          <label htmlFor="sales_mode">
                                            {t("Sales Mode")}
                                          </label>
                                        </div>
                                        <div className="col-sm-10">
                                          <div>
                                            {/* <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Sales mode"
                                              name="sales_mode"
                                              id="sales_mode"
                                              onChange={(e) => handleChange(e)}
                                            /> */}
                                            <select
                                              className="form-control"
                                              onChange={(e) => handleChange(e)}
                                              name="sales_mode">
                                              <option>
                                                --{t("Select Option")}--
                                              </option>
                                              <option value="Cash">
                                                {t("Cash")}
                                              </option>
                                              <option value="Non cash">
                                                {t("Non Cash")}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="row align-items-center">
                                        <div className="col-sm-2">
                                          <label htmlFor="sales_source">
                                            {" "}
                                            {t("Sales source")}
                                          </label>
                                        </div>
                                        <div className="col-sm-10">
                                          <div>
                                            <div className="input-group">
                                              <div className="react-datepicker-wrapper">
                                                <div className="react-datepicker__input-container">
                                                  <input
                                                    type="text"
                                                    name="sales_source"
                                                    className="form-control"
                                                    onChange={(e) =>
                                                      handleChange(e)
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              {/* <div className="input-group-append">
                                                <div className="input-group-text">
                                                  <i className="fas fa-calendar-alt"></i>
                                                </div>
                                              </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group undefined">
                                      <div className="row align-items-center">
                                        <div className="col-sm-2">
                                          <label htmlFor="buyer_id">
                                            {t("Buyer ID")}
                                          </label>
                                        </div>
                                        <div className="col-sm-10">
                                          <div>
                                            <input
                                              type="text"
                                              name="buyer_id"
                                              className="form-control"
                                              value={elements.buyer_id}
                                              onChange={(e) =>
                                                handleBuyerfilter(e)
                                              }
                                            />
                                            {elements.buyer_id.length > 0 &&
                                              noMatch && (
                                                <div className="error-message">
                                                  No matching buyer ID found.
                                                </div>
                                              )}
                                            {elements.buyer_id.length > 0 &&
                                              !noMatch && (
                                                <ul>
                                                  {suggestions.map((buyer) => (
                                                    <li
                                                      key={buyer.buyer_id}
                                                      style={{
                                                        border:
                                                          "1px solid #000",
                                                        padding: "5px 15px",
                                                        cursor: "pointer",
                                                        width: "35%",
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-around",
                                                      }}
                                                      onClick={() =>
                                                        handleBuyerSuggestionClick(
                                                          buyer.buyer_id
                                                        )
                                                      }>
                                                      <p>{buyer.buyer_id}</p>
                                                      <p>
                                                        {nameToStarConverter(
                                                          buyer.name_surname
                                                        )}
                                                      </p>
                                                    </li>
                                                  ))}
                                                </ul>
                                              )}
                                            {/* <select className="form-control" onChange={(e) => handleChange(e)} name="buyer_id">
                                              {buyerData.map(i => (
                                                <option value={i.user}>{i.name_surname}</option>
                                              ))}
                                            </select> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <input
                                      type="hidden"
                                      name="seller_id"
                                      value={user.user_id_info}
                                      onChange={(e) => handleChange(e)}
                                    />

                                    <input
                                      type="hidden"
                                      name="gold_bar_price"
                                      value={gold_price}
                                      onChange={(e) => handleChange(e)}
                                    />
                                    <div className="form-group undefined">
                                      <div className="row align-items-center">
                                        <div className="col-sm-2">
                                          <label htmlFor="partner_id">
                                            {t("Partner ID")}
                                          </label>
                                        </div>
                                        <div className="col-sm-10">
                                          <div>
                                            <input
                                              type="text"
                                              name="partner_id"
                                              className="form-control"
                                              value={elements.partner_id}
                                              onChange={(e) =>
                                                handlePartnerfilter(e)
                                              }
                                            />
                                            {elements.partner_id.length > 0 &&
                                              noPartnerMatch && (
                                                <div className="error-message">
                                                  No matching Partner ID found.
                                                </div>
                                              )}
                                            {elements.partner_id.length > 0 &&
                                              !noPartnerMatch && (
                                                <ul>
                                                  {suggestionsPartner.map(
                                                    (partner) => (
                                                      <li
                                                        key={partner.partner_id}
                                                        style={{
                                                          border:
                                                            "1px solid #000",
                                                          padding: "5px 15px",
                                                          cursor: "pointer",
                                                          width: "35%",
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-around",
                                                        }}
                                                        onClick={() =>
                                                          handlePartnerSuggestionClick(
                                                            partner.partner_id
                                                          )
                                                        }>
                                                        <p>
                                                          {partner.partner_id}
                                                        </p>
                                                        <p>
                                                          {nameToStarConverter(
                                                            partner.name_surname
                                                          )}
                                                        </p>
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              )}
                                            {/* <select className="form-control" onChange={(e) => handleChange(e)} name="partner_id">
                                              {
                                                partnerData.map(i => (
                                                  <option value={i.user}>{i.name_surname}</option>
                                                ))
                                              }
                                            </select> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <div className="row align-items-center justify-content-end">
                                    <div className="col-sm-3">
                                      <button
                                        type="submit"
                                        className="seller-form-submit">
                                        {t("Save")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>

                            {/* --------- */}
                          </div>
                        </div>
                        {/* ----- */}
                      </div>
                    </div>
                    {/* -------------------------- */}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
