import {
  FacebookAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import initializeFireBaseApp from "./FireBaseInit";
import { GoogleAuthProvider } from "firebase/auth";
import { useEffect, useState } from "react";

initializeFireBaseApp();
const Googleprovider = new GoogleAuthProvider();
const Facebookprovider = new FacebookAuthProvider();

const UserFirebaseEmailSystem = () => {
  const [user, setUser] = useState(null);

  const auth = getAuth();

  function SignInwithemailLogin() {
    signInWithPopup(auth, Googleprovider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;

        const user = result.user;

        // console.log(token, user);
      })
      .catch((error) => {});
  }

  function FacebookLogin() {
    const auth = getAuth();
    signInWithPopup(auth, Facebookprovider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        // console.log(credential, user, accessToken);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {});
  }

  useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        console.log(user);
      } else {
        setUser(false);
      }
    });

    // Cleanup function to unsubscribe
    return () => {
      unSubscribe();
    };
  }, [auth]);

  // log out
  function signOutuser() {
    signOut(auth)
      .then(() => {
        setUser({});
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }

  return {
    user,
    SignInwithemailLogin,
    FacebookLogin,
    signOutuser,
  };
};

export default UserFirebaseEmailSystem;
