import React, { useEffect } from "react";
import { useState } from "react";
import "./Newses.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useGetEffectItems from "../../Hooks/useSingleItemGetEffect";
// import TrendingPopularNews from "../News/TrendingPopularNews";
import { useTranslation } from "react-i18next";
import PopularAndTrandingNews from "../News/PopularAndTrandingNews";

const NewsSideBar = [
  {
    name: "TRENDING",
    id: 1,
  },
  {
    name: "POPULAR",
    id: 2,
  },
];
const Newses = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n?.language || "en";
  const { id: newsId } = useParams();
  // get tour single id
  const { data, error } = useGetEffectItems(`newsWise/${newsId}`);
  const [activeFilter, setActiveFilter] = useState("Description");
  const { id, popular, latest, tag, image, created_by, publishing_time } =
    data || {};
  const category = [{ name: "Description" }];
  const [selected, setselected] = useState(1);
  const [searchText, setSearchText] = useState("");

  const handlerSearch = (e) => {
    setSearchText(e.target.value);
  };

  // trending and popular part
  const [TrendingNews, setTrendingNews] = useState(null);
  const [PopularNews, setPopularNews] = useState(null);

  useEffect(() => {
    apiGetfeatureNews();
  }, []);

  const apiGetfeatureNews = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_Url}/news`);
      const jsondata = await response.json();

      const newsData = jsondata?.news?.map((item) => {
        return {
          ...item,
          en_description: item.description,
          en_tag: item.description,
          en_title: item.title,
        };
      });

      //filter by exclusive latest popular status
      newsData?.filter((item) => {
        if (item.latest) {
          setTrendingNews((prev) => {
            if (prev?.length > 0) {
              return [...prev, item];
            } else {
              return [item];
            }
          });
        }
        if (item.popular) {
          setPopularNews((prev) => {
            if (prev?.length > 0) {
              return [...prev, item];
            } else {
              return [item];
            }
          });
        }
      });
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  if (data === null) return;

  const description = data[`${lang}_description`] || "";
  const title = data[`${lang}_title`] || "";

  return (
    <div>
      <div className="container header-top">
        <div className="row">
          <div className="col-12 col-md-9">
            <div>
              {image && (
                <img
                  src={
                    `${process.env.REACT_APP_IMAGE_URL}/admin/assets/images/news/` +
                    image
                  }
                  alt="news posts"
                />
              )}
            </div>
            <h1 className="hotelTitle">{title}</h1>
            <div className="article-meta ">
              <p className="article-date">
                <i className="fa-solid fa-at mr-2"></i>
                <time>{created_by}</time>
              </p>

              <p className="article-comments">
                <i className="fa-solid fa-calendar-days  mr-2"></i>Date: 31
                January 2019 , 14:56
              </p>
            </div>

            <div className="hotelDetails">
              <div className="hotelDetailsTexts">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12">
                    <ul
                      className="project-nav project-isotope-filter"
                      style={{ display: "flex", justifyContent: "left" }}>
                      {category.map(({ name }, id) => {
                        return (
                          <li
                            key={id}
                            onClick={() => {
                              setActiveFilter(name);
                            }}
                            className={activeFilter === name ? "active" : ""}>
                            {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <p className="hotelDesc">{description}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-className=3">
            <PopularAndTrandingNews />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newses;
