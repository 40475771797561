import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TourDescription = ({ ...tourDescriptionContent }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n?.language || "en";

  const { images } = tourDescriptionContent || {};

  const [sliderImage] = useState(() =>
    images.map((item) => {
      return {
        image: process.env.REACT_APP_IMAGE_URL + item.image,
      };
    })
  );

  const name = tourDescriptionContent[`${lang}_name`] || "";
  const description = tourDescriptionContent[`${lang}_description`] || "";

  const [slideNumber, setSlideNumber] = useState(0);

  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber =
        slideNumber === 0 ? sliderImage.length - 1 : slideNumber - 1;
    } else {
      newSlideNumber =
        slideNumber === sliderImage.length - 1 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };

  return (
    <div className="row pb-30">
      <div className="col-lg-5">
        <div
          className="about-content-box about-content-box-one mb-4 wow fadeInLeft"
          data-wow-delay=".3s"
          style={{
            visibility: "visible",
            animationDelay: "0.3s",
          }}>
          <div className="section-title section-title-left mb-4 mt-4">
            <span className="sub-title">{t("THE MOST EXCLUSIVE TOUR")}</span>
          </div>
        </div>
      </div>
      <div className="row tour-feature position-relative mx-auto">
        <div className="col-lg-5 d-flex flex-column ">
          <div
            style={{ textAlign: "justify", color: "white" }}
            dangerouslySetInnerHTML={{ __html: description }}></div>
          <div
            className="article-meta"
            style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="article-date">
              <i className="icon fa-regular fa-calendar" aria-hidden="true"></i>
              <time>4 april 2023</time>
            </p>
          </div>
        </div>
        <div className="col-lg-7 ">
          <div
            className="mt-4 mt-md-0 position-relative about-img-one wow fadeInUp tour-image-silder"
            data-wow-delay=".45s">
            <i
              className="fa-solid fa-angle-left text-white rounded-circle  pl-2 pl-md-4  position-absolute z-3 top-50 start-0 translate-middle"
              onClick={() => handleMove("l")}></i>
            <img
              src={sliderImage[slideNumber].image}
              data-src={sliderImage[slideNumber].image}
              alt="about"
              data-ll-status="loaded"
            />
            <i
              className="fa-solid fa-angle-right text-white pr-2 pr-md-4  position-absolute z-3 top-50 start-100 translate-middle"
              onClick={() => handleMove("r")}></i>
          </div>
          <p
            className="d-none d-md-block position-absolute  top-50 color-white"
            style={{
              transform: "rotate(90deg)",
              right: "-28px",
              color: "white",
            }}>
            {name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TourDescription;
