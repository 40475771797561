import { Link } from "react-router-dom";
import ProfileImg from "../../assets/profile.png";
import OrdersImg from "../../assets/my-orders.png";
import { useTranslation } from "react-i18next";

export const SellingInformationOption = () => {
  const { t } = useTranslation();

  return (
    <div className="bordered-shadow-box">
      <div className="bordered-shadow-box-overflow-hidden">
        <div className="seller-profile-sidebar">
          {/* ---main sidebar */}
          <div className="seller-profile-sidebar-collapsed-menu-area first">
            <a
              href="#/#"
              className="seller-profile-sidebar-collapsed-menu-header"
              data-toggle="collapse"
              to="#profile-options"
              role="button"
              aria-expanded="true"
              aria-controls="m-profile-options">
              <span> {t("Selling Information Options")}</span>
              <span className="icon">
                <i className="fas fa-chevron-right"></i>
              </span>
            </a>
            <div
              id="profile-options"
              className="seller-profile-sidebar-menu collapse show">
              <ul>
                <li>
                  <Link to="/seller-profile">
                    <span className="icon">
                      {" "}
                      <img src={ProfileImg} alt="" />{" "}
                    </span>
                    <span className="text">{t("Selling Information")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/seller-transitions">
                    <span className="icon">
                      {" "}
                      <img src={OrdersImg} alt="" />{" "}
                    </span>
                    <span className="text">{t("Transitions")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/*----------- */}
        </div>
      </div>
    </div>
  );
};

export const SellingHeadersPart = () => {
  const { t } = useTranslation();

  return (
    <div className="seller-profile-content-header">
      <h3>{t(`Selling Information`)}</h3>
      <div className="seller-profile-content-breadcumb">
        <ul>
          <li>
            <a href="/">{t(`Home`)}</a>
          </li>
          <li>
            {
              // to="/customer/dashboard"
            }
            <a href="/">{t(`Selling Information`)}</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
