import React, { useEffect, useState } from "react";
import "./HomePageTable.css";
import { FaEuroSign } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

function HomePageTable() {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetch(`https://eastgold.az/admin/api/buyback`)
      .then((res) => res.json())
      .then((data) => setTableData(data));
  }, []);

  console.log(tableData);

  return (
    <div className="">
      <h4 className="tableTitle" style={{ color: "#B68C5A" }}>
        {t("Buy Back Price For 21.02.2024")}
      </h4>
      <div className="table-responsive">
        <table
          className="table table-bordered"
          style={{
            marginBottom: "50px",
            borderCollapse: "collapse",
            border: "2px solid #B5987F",
          }}>
          <thead>
            <tr>
              {tableData.map((item, index) => {
                return (
                  <th className="text-center" style={{ color: "#B68C5A" }}>
                    Gold {item?.size}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {tableData.map((item, index) => {
                return (
                  <td className="text-center" style={{ color: "#B68C5A" }}>
                    {item?.price} ₼
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default HomePageTable;
