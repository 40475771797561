import React, { useEffect, useState } from "react";
import FeatureItems from "../../../components/FeatureItems/FeatureItems";
import "./Tour.css";
import TourDescription from "./TourDescription";
import SearchInformation from "../../../components/SearchInformation/SearchInformation";

function CheckText(description) {
  // remove html using regular expression
  const textWithoutTags = description.replace(/<[^>]+>/g, "");

  // Split the text content into words
  const words = textWithoutTags.trim().split(/\s+/);

  // Select the first 10 words
  const first10Words = words.slice(0, 7).join(" ");

  return first10Words;
}

const Tour = () => {
  const [tourDescriptionContent, setTourDescriptionContent] = useState(null);
  const [featureTour, setFeatureTour] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");

  const [filterSearch, setFilterSearch] = useState(null);

  const searchFilterHandler = (e) => {
    setSearchFilter(e.target.value);
  };

  // search tour places
  useEffect(() => {
    if (searchFilter.trim() !== "") {
      searchParam();
    }
  }, [searchFilter]);

  async function searchParam() {
    const response = await fetch(
      `https://eastgold.az/admin/api/tourSearch?search=${searchFilter}`
    );
    const jsondata = await response.json();

    const TourData = jsondata?.message?.map((tour) => {
      return {
        ...tour,
        en_name: tour.name,
        en_description: CheckText(tour.description),
        en_location: tour.location,
      };
    });

    // filter the search
    setFilterSearch(TourData);
  }

  const apiGetfeatureTour = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_Url}/tours`);
      const jsondata = await response.json();

      const TourData = jsondata?.tours?.map((tour, index) => {
        if (index === 0) {
          return {
            ...tour,
            en_name: tour.name,
            en_description: tour.description,
            en_location: tour.location,
          };
        }

        return {
          ...tour,
          en_name: tour.name,
          en_description: CheckText(tour.description),
          en_location: tour.location,
        };
      });
      // for tour description only
      setTourDescriptionContent(TourData[0]);
      // for all the rest tour
      setFeatureTour(TourData.slice(1, TourData.length));
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  useEffect(() => {
    apiGetfeatureTour();
  }, []);

  // for feature all the tour
  let featureTourContent = null;

  if (!featureTour) {
    featureTourContent = <h1>Loading .....</h1>;
  } else if (featureTour?.length === 0) {
    featureTourContent = <h1>No Feature Tour Avaible</h1>;
  } else if (featureTour?.length > 0) {
    featureTourContent = (
      <FeatureItems FeatureText="Feature Tour" data={featureTour} />
    );
  }
  // filterSearch
  let Tourplace = null;

  if (!filterSearch) {
    Tourplace = <h1>Comming Tour place...</h1>;
  } else if (filterSearch?.length === 0) {
    Tourplace = <h1>No Feature Tour Avaible</h1>;
  } else if (filterSearch?.length > 0) {
    console.log(featureTour, "Search Result Tour");
    Tourplace = (
      <FeatureItems FeatureText="Search Result Tour" data={filterSearch} />
    );
  }

  return (
    <div className="container header-top">
      {/* search for Information */}
      <SearchInformation
        handler={searchFilterHandler}
        filterText={searchFilter}
      />
      {/* tour decription */}
      {searchFilter.trim() === "" && tourDescriptionContent !== null && (
        <TourDescription {...tourDescriptionContent} />
      )}

      {/* Feature all the tour list */}
      {searchFilter.trim() === "" && (
        <>
          <div>{featureTourContent}</div>
        </>
      )}

      {/* Feature search tour list */}
      {searchFilter.trim() !== "" && (
        <>
          <div>{Tourplace}</div>
        </>
      )}
    </div>
  );
};

export default Tour;
