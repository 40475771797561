import React, { useEffect, useMemo, useState } from "react";
import "../Portfolios/Portfolio.css";
import "react-animated-slider/build/horizontal.css";
import { useTranslation } from "react-i18next";
import headerImage from "./../../assets/loginHeaderImage.png";
import ProductCart from "./ProductCart";
import Button from "../../components/Button/Button";
import PreLoaderCard from "../../components/PreLoaderCard/PreLoaderCard";
import SingleContent from "../../components/PreLoaderCard/SingleContent";
import { useRef } from "react";
import { Category } from "@material-ui/icons";
//import { Translation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
export default function Portfolios() {
  const history = useHistory();
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [activeFilter, setActiveFilter] = useState("*");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);

  const searchParams = new URL(document.location).searchParams;
  const getParams = searchParams.get("category") || "All";

  const [visible, setVisible] = useState(9);

  const refchecker = useRef(false);

  const { t, i18n } = useTranslation();

  const lang = i18n?.language || "en";

  const apiGetProducts = async () => {
    setProductLoading(false);
    try {
      const response = await fetch(`${process.env.REACT_APP_Url}/products`);
      const jsondata = await response.json();

      const productsData = jsondata.map((product) => {
        return {
          ...product,
          en_product_name: product.product_name,
          en_product_desc: product.product_desc,
        };
      });

      setProduct(productsData);

      setProductLoading(true);
      refchecker.current = true;
    } catch (error) {
      console.error("API request error:", JSON.stringify(error));
    }
  };

  useEffect(() => {
    apiGetProducts();
  }, []);
  // product data end

  // category data start
  const apiGetCategory = async () => {
    setLoading(false);
    try {
      const response = await fetch(`${process.env.REACT_APP_Url}/category`);
      const jsondata = await response.json();

      setCategory([
        {
          name: "All",
          az_name: "Hamısı",
          ru_name: "Все",
          id: "*",
        },
        ...jsondata,
      ]);

      setLoading(true);
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  useEffect(() => {
    apiGetCategory();
  }, []);

  // category data end

  // Filter the projects based on the active filter
  useEffect(() => {
    if (activeFilter === "*") {
      setFilteredProjects(product);
    } else {
      const filtered = product.filter(
        (project) => project.category_id == activeFilter
      );

      setFilteredProjects(filtered);
    }
  }, [product, activeFilter, lang]);
  // Filter the projects based on the active filter

  useEffect(() => {
    if (getParams) {
      const getCategory =
        category?.find(
          (category) =>
            category?.name?.toLowerCase().includes(getParams?.toLowerCase()) ||
            category?.az_name?.toLowerCase().includes(getParams?.toLowerCase())
        ) || "*";
      if (!getCategory?.id) return;

      setActiveFilter(getCategory.id);
    }
  }, [category, getParams]);

  // load Data with button
  const loadMore = () => {
    setVisible((prev) => parseInt(prev) + 3);
  };

  return (
    <>
      {/* ---------- Breadcrumb Section -------- */}
      <section
        className="section-header-part breadcrumb-section  lazy entered loaded"
        data-ll-status="loaded">
        <div className="container"></div>
      </section>

      {/* ----------  Project Section -------- */}
      <section className="project-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              {/* Filter buttons */}
              <ul className="project-nav project-isotope-filter">
                {!loading && <SingleContent />}

                {category.map((categorys) => {
                  const { id } = categorys || {};

                  //  reassing key name to en_name
                  categorys["en_name"] = categorys["name"];

                  const categoryName = categorys[`${lang}_name`];

                  if (!categoryName) return;

                  return (
                    <li
                      key={id}
                      onClick={(event) => {
                        searchParams.set("category", categoryName);
                        setActiveFilter(id);
                        history.push(`/products?${searchParams.toString()}`);
                      }}
                      className={activeFilter === id ? "active" : ""}>
                      {categoryName}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          {/* <!-- Project Boxes --> */}
          <div
            className="row project-boxes project-isotope mt-60 justify-content-center"
            style={{
              position: "relative",
              marginTop: "3rem",
            }}>
            {!productLoading && <PreLoaderCard />}

            {productLoading && filteredProjects?.length > 0 ? (
              filteredProjects?.slice(0, visible).map((project, index) => {
                const { id } = project || {};

                const leftPosition = (index % 3) * 33.3333;
                const topPosition = Math.floor(index / 3) * 400;

                return <ProductCart key={id} lang={lang} project={project} />;
              })
            ) : refchecker?.current ? (
              <p
                style={{
                  fontSize: "20px",
                  color: "white",
                  textAlign: "center",
                }}>
                There is no product available
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div style={{ textAlign: "center", marginTop: "45px" }}>
          {visible < filteredProjects?.length && (
            <Button onClick={loadMore}>See More</Button>
          )}
        </div>

        <br></br>
        <br></br>
      </section>
    </>
  );
}
