import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const ItemCard = (data) => {
  const location = useLocation();
  const { pathname } = location;

  const { t, i18n } = useTranslation();

  const lang = i18n?.language || "en";

  const { id, price, rating = 0, images } = data.data || {};

  const name = data.data[`${lang}_name`] || "";
  const short_description = data.data[`${lang}_short_description`] || "";
  const description = data.data[`${lang}_description`] || "";

  const [sliderImage] = useState(() =>
    images.map((item) => {
      return {
        image: process.env.REACT_APP_IMAGE_URL + item.image,
      };
    })
  );

  const [slideNumber, setSlideNumber] = useState(0);

  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber =
        slideNumber === 0 ? sliderImage.length - 1 : slideNumber - 1;
    } else {
      newSlideNumber =
        slideNumber === sliderImage.length - 1 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };

  const ratingShow = !isNaN(rating)
    ? Array.from(Array(Number(rating)), (x, i) => i + 1).map((item, index) => {
        return (
          <i
            key={index}
            className="icon fa-regular fa-star"
            aria-hidden="true"></i>
        );
      })
    : null;

  return (
    <div className="box-feature">
      <div
        className="position-relative about-img-one wow fadeInUp tour-image-silder"
        data-wow-delay=".45s">
        <i
          className="fa-solid fa-angle-left text-white rounded-circle  pl-2 pl-md-4  position-absolute z-3 top-50 start-0 translate-middle"
          onClick={() => handleMove("l")}></i>
        <img
          src={sliderImage[slideNumber].image}
          data-src={sliderImage[slideNumber].image}
          data-ll-status="loaded"
          alt="slider hotel"
          style={{
            height: "270px",
            objectFit: "cover",
          }}
        />
        <i
          className="fa-solid fa-angle-right text-white pr-2 pr-md-4  position-absolute z-3 top-50 start-100 translate-middle"
          onClick={() => handleMove("r")}></i>
      </div>

      <a href={`${pathname}/${id}`}>
        <div className="ps-2 pt-2 pb-4">
          <div className="article-meta">
            <p>{name}</p>
          </div>
          <div
            className="article-meta text-white"
            dangerouslySetInnerHTML={{
              __html: short_description || description,
            }}></div>
          <div className="article-meta mt-3">
            <p className="article-date">
              <i className="icon fa-regular fa-dollar" aria-hidden="true"></i>
              <time>{price}</time>
            </p>
            <p className="article-comments">{ratingShow}</p>
            <p className="article-comments">(249 Reviews)</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ItemCard;
