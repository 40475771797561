import React, { useEffect, useRef, useState } from "react";
import { CiLocationOn } from "react-icons/ci";
import { CiCalendarDate } from "react-icons/ci";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./Hotel.css";

const HoteSearch = ({ handlerSearchHotel }) => {
  const [destination, setDestination] = useState("");
  const searchRef = useRef(null);
  const [openDate, setOpenDate] = useState(false);
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [openOptions, setOpenOptions] = useState(false);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
  });

  const handleOption = (name, operation) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: operation === "i" ? options[name] + 1 : options[name] - 1,
      };
    });
  };

  const [dateResize, setdateResize] = useState("horizontal");

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    // when the window is resized the date picker display change
    if (window.innerWidth < 1000) {
      setdateResize("vertical");
    } else {
      setdateResize("horizontal");
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  // if user click outside the search area close the date picker and option
  useEffect(() => {
    document.addEventListener("mousedown", handlerOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handlerOutsideClick);
    };
  }, []);

  const handlerOutsideClick = (e) => {
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setOpenOptions(false);
      setOpenDate(false);
    }
  };

  // style for calender width as per window size
  const style =
    window.innerWidth < 400
      ? {
          width: `${windowSize[0] - 50}px !important`,
        }
      : {};

  //search a sepecifice hotels
  // const handlerSearchHotel = () => {
  //   console.log(destination, dates, options);
  // };

  return (
    <div ref={searchRef} className="bg-white p-4 rounded-lg shadow-lg">
      <div className="row justify-content-center align-items-center">
        <div className="col-md-3">
          <label className="text-black ">Destination</label>
          <div className="position-relative">
            <input
              type="text"
              className="form-control mt-1 border-none"
              placeholder="Enter Location"
              style={{
                paddingLeft: "30px",
                fontSize: "15px",
              }}
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
            />
            <CiLocationOn
              className="position-absolute top-50 translate-middle text-black  fs-4"
              style={{ left: "14px" }}
            />
          </div>
        </div>

        <div className="col-md-3">
          <label className="text-black ">Departing | Returing</label>
          <div className="position-relative">
            <input
              type="text"
              className="form-control mt-1 border-none"
              placeholder="Enter Departer"
              style={{
                paddingLeft: "30px",
                fontSize: "15px",
              }}
              onClick={() => {
                setOpenOptions(false);
                setOpenDate(!openDate);
              }}
              value={`${format(dates[0].startDate, "MM/dd/yyyy")} to ${format(
                dates[0].endDate,
                "MM/dd/yyyy"
              )}`}
            />
            <CiCalendarDate
              onClick={() => {
                setOpenOptions(false);
                setOpenDate(!openDate);
              }}
              className="position-absolute top-50 translate-middle text-black  fs-4"
              style={{ left: "14px" }}
            />

            {openDate && (
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setDates([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dates}
                className="date"
                months={2}
                direction={dateResize}
                minDate={new Date()}
                style={style}
              />
            )}
          </div>
        </div>

        <div className="col-md-3">
          <label className="text-black ">Number Of Member</label>
          <div className="position-relative">
            <input
              onClick={() => {
                setOpenDate(false);
                setOpenOptions(!openOptions);
              }}
              type="text"
              className="form-control mt-2"
              placeholder={`${options.adult} adult · ${options.children} children · ${options.room} room`}
              value={`${options.adult} adult · ${options.children} children · ${options.room} room`}
            />
          </div>
          {openOptions && (
            <div className="options">
              <div className="optionItem">
                <span className="optionText">Adult</span>
                <div className="optionCounter">
                  <button
                    disabled={options.adult <= 1}
                    className="optionCounterButton"
                    onClick={() => handleOption("adult", "d")}>
                    -
                  </button>
                  <span className="optionCounterNumber">{options.adult}</span>
                  <button
                    className="optionCounterButton"
                    onClick={() => handleOption("adult", "i")}>
                    +
                  </button>
                </div>
              </div>
              <div className="optionItem">
                <span className="optionText">Children</span>
                <div className="optionCounter">
                  <button
                    disabled={options.children <= 0}
                    className="optionCounterButton"
                    onClick={() => handleOption("children", "d")}>
                    -
                  </button>
                  <span className="optionCounterNumber">
                    {options.children}
                  </span>
                  <button
                    className="optionCounterButton"
                    onClick={() => handleOption("children", "i")}>
                    +
                  </button>
                </div>
              </div>
              <div className="optionItem">
                <span className="optionText">Room</span>
                <div className="optionCounter">
                  <button
                    disabled={options.room <= 1}
                    className="optionCounterButton"
                    onClick={() => handleOption("room", "d")}>
                    -
                  </button>
                  <span className="optionCounterNumber">{options.room}</span>
                  <button
                    className="optionCounterButton"
                    onClick={() => handleOption("room", "i")}>
                    +
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className="col-md-2 text-center"
          style={{
            marginTop: "46px ",
            fontSize: "15px",
          }}>
          <button
            onClick={() => handlerSearchHotel({ destination, dates, options })}
            className="btn"
            style={{
              background: "#b68c5a",
              color: "white",
            }}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default HoteSearch;
