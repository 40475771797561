import React, { useEffect, useState } from "react";
import "./Hotel.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import FeatureItems from "../../components/FeatureItems/FeatureItems";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import LuxiousHotel from "./LuxiousHotel";
import FeatureHotel from "./FeatureHotel";
import HoteSearch from "./HoteSearch";

const Hotel = () => {
  const [featureHotel, setFeatureHotel] = useState(null);
  const [searchResult, setSearhResult] = useState(null);

  const apiGetfeatureHotel = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_Url}/hotels`);
      const jsondata = await response.json();

      const HotelData = jsondata?.hotels?.map((hotel) => {
        return {
          ...hotel,
          en_description: hotel.description,
          en_location: hotel.location,
          en_name: hotel.name,
          en_short_description: hotel.short_description,
        };
      });
      setFeatureHotel(HotelData);
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  useEffect(() => {
    apiGetfeatureHotel();
  }, []);

  // for feature all the hotel
  let featureHotelContent = null;

  if (!featureHotel) {
    featureHotelContent = <h1></h1>;
  } else if (featureHotel?.length === 0) {
    featureHotelContent = <h1>No Feature Hotel Avaible</h1>;
  } else if (featureHotel?.length > 0) {
    featureHotelContent = (
      <FeatureItems data={featureHotel} FeatureText="FEATURE HOTEL" />
    );
  }

  // for LuxiousHotel
  let LuxiousHotelContent = null;

  if (!featureHotel) {
    LuxiousHotelContent = <h1></h1>;
  } else if (featureHotel?.length === 0) {
    LuxiousHotelContent = <h1>No Feature Hotel Avaible</h1>;
  } else if (featureHotel?.length > 0) {
    LuxiousHotelContent = (
      <LuxiousHotel data={featureHotel} FeatureText="LUXURIOUS HOTEL" />
    );
  }

  // for Top Hotel
  let TopHotelContent = null;

  if (!featureHotel) {
    TopHotelContent = <h1></h1>;
  } else if (featureHotel?.length === 0) {
    TopHotelContent = <h1>No Top Hotel Avaible</h1>;
  } else if (featureHotel?.length > 0) {
    TopHotelContent = (
      <FeatureHotel data={featureHotel} FeatureText="TOP HOTEL" />
    );
  }

  const handlerSearchHotel = async (param) => {
    const { destination } = param;
    if (destination.trim() === "") {
      setSearhResult(null);
      return;
    } else {
      try {
        const searcHotelResponse = await fetch(
          `https://eastgold.az/admin/api/hotelSearch?search=${destination}`
        );
        const data = await searcHotelResponse.json();

        const searchHotelResult = data.message;

        setSearhResult(searchHotelResult);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  // for search all the hotel
  let searchHotelContent = null;

  if (!searchResult) {
    searchHotelContent = <h1></h1>;
  } else if (searchResult?.length === 0) {
    searchHotelContent = <h1>No Feature Hotel Availble</h1>;
  } else if (searchResult?.length > 0) {
    searchHotelContent = (
      <FeatureHotel data={searchResult} FeatureText="Neartest Hotel" />
    );
  }

  return (
    <div className="container header-top">
      <HoteSearch handlerSearchHotel={handlerSearchHotel} />
      {searchResult === null ? (
        <>
          {" "}
          {featureHotelContent}
          {LuxiousHotelContent}
          {TopHotelContent}
        </>
      ) : (
        <> {searchHotelContent}</>
      )}
    </div>
  );
};

export default Hotel;
