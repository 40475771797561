import React from "react";

const ItemCardNews = (data) => {
  const {
    id,
    description = "",
    title,
    created_by,
    tag,
    image,
    publishing_time,
  } = data.data || {};

  return (
    <div className="top-hotel-feature">
      <div
        className="position-relative about-img-one wow fadeInUp tour-image-silder"
        data-wow-delay=".45s">
        <img
          src={
            `${process.env.REACT_APP_IMAGE_URL}/admin/assets/images/news/` +
            image
          }
          data-ll-status="loaded"
          alt="slider hotel"
          style={{
            objectFit: "cover",
          }}
        />
      </div>

      <a href={`/news/${id}`}>
        <div className="ps-2 pt-2 pb-4">
          <div className="article-meta">
            <p>{title}</p>
          </div>
          <div className="article-meta text-white">
            {description.slice(0, 20)}
          </div>
        </div>
      </a>
    </div>
  );
};

export default ItemCardNews;
