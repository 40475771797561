import React, { useEffect } from "react";
import { useState } from "react";
import "./Tours.css";
import { useParams } from "react-router-dom";
import useGetEffectItems from "../../Hooks/useSingleItemGetEffect";
import { useTranslation } from "react-i18next";

const Tours = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n?.language || "en";

  const { id: tourId } = useParams();
  // get tour single id
  const { data, error } = useGetEffectItems(`toursWise/${tourId}`);

  const { id, images, member, price, status, updated_at } = data || {};

  const [activeFilter, setActiveFilter] = useState("Description");

  const category = [{ name: "Description" }];

  const [sliderImage, setSliderImage] = useState([]);

  useEffect(() => {
    if (images?.length > 0) {
      setSliderImage(
        images?.map((item) => {
          return {
            image: process.env.REACT_APP_IMAGE_URL + item.image,
          };
        })
      );
    }
  }, [images]);
  const [open, setOpen] = useState(false);
  const [slideNumber, setSlideNumber] = useState(0);
  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };
  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber =
        slideNumber === 0 ? sliderImage.length - 1 : slideNumber - 1;
    } else {
      newSlideNumber =
        slideNumber === sliderImage.length - 1 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };

  if (data === null) return;

  const name = data[`${lang}_name`] || "";
  const description = data[`${lang}_description`] || "";
  const location = data[`${lang}_location`] || "";

  return (
    <div>
      <div
        className="hotelContainer  header-top"
        style={{ marginTop: "150px", marginBottom: "100px" }}>
        {open && (
          <div className="slider">
            <i
              className="fa-solid fa-xmark close"
              onClick={() => setOpen(false)}></i>

            <i
              className="fa-solid fa-circle-arrow-left arrow"
              onClick={() => handleMove("l")}></i>

            <div className="sliderWrapper">
              <img
                src={sliderImage[slideNumber].image}
                alt=""
                className="sliderImg"
              />
            </div>

            <i
              className="fa-solid fa-circle-arrow-right arrow"
              onClick={() => handleMove("r")}></i>
          </div>
        )}
        <div className="container ">
          <div className="section-title section-title-left mb-40">
            <span className="sub-title">The Most Exclusive Tour Places</span>
          </div>
          <div
            className="radio-input mt-4 mb-4"
            style={{ display: "flex", justifyContent: "left" }}>
            <input
              value="value-1"
              name="value-radio"
              id="value-1"
              type="radio"
            />
            <label htmlFor="value-1">{`Tour / Location / ${location}`}</label>
          </div>
          <h1 className="hotelTitle">{name}</h1>
          <div className="article-meta mb-2">
            <p className="article-comments">
              <i className="fa-solid fa-calendar-days  mr-2"></i>Date: 31
              January 2019 , 14:56
            </p>
          </div>

          <div className="d-flex justify-content-between">
            <div className="w-100">
              <div className="hotelImages">
                {sliderImage.map(({ image }, i) => {
                  return (
                    <div className="hotelImgWrapper" key={i}>
                      <img
                        onClick={() => handleOpen(i)}
                        src={image}
                        alt=""
                        className="hotelImg h-100"
                      />
                    </div>
                  );
                })}
              </div>

              <div className="hotelDetails">
                <div className="hotelDetailsTexts">
                  <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12">
                      <ul
                        className="project-nav project-isotope-filter"
                        style={{ display: "flex", justifyContent: "left" }}>
                        {category.map(({ name }, id) => {
                          return (
                            <li
                              key={id}
                              onClick={() => {
                                setActiveFilter(name);
                              }}
                              className={activeFilter === name ? "active" : ""}>
                              {name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  {activeFilter === "Description" && (
                    <div
                      className="mt-3"
                      dangerouslySetInnerHTML={{ __html: description }}></div>
                  )}

                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tours;
