export function formatNumberWithCommas(number) {
  const strNumber = number.toString();
  const length = strNumber.length;

  if (length <= 3) {
    return strNumber;
  }
 
  const lastThreeDigits = strNumber.slice(-3);
  const remainingDigits = strNumber.slice(0, -3);

  const formattedNumber =
    remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    "," +
    lastThreeDigits;

  return formattedNumber;
}
