import React, { useRef, useEffect } from "react";

// import LogoImg from "../../assets/logo2.png";
// import LogoImg2 from "../../assets/logo.svg";
// import LogoImg2 from "../../assets/Logo.png";
// import LogoImg2 from "../../assets/updatedmailogo.png";

import LogoImg2 from "../../assets/mainlogo.svg";

// import LogoImg2 from "../../assets/logo.svg";
// import CrossImg from "../../assets/cross.png";
import "../../assets/css/Blogs.css";
import "../../assets/css/template.css";
import { Translation } from "react-i18next";
import { useTranslation } from "react-i18next";
import ChangeLang from "../ChangeLang";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import Swal from "sweetalert2";
import { useAuth } from "../../Auth/UseAuth";

const contact_Number = process.env.REACT_APP_CONTACT_NUMBER || "";

export default function Navbar() {
  const { signOutuser } = useAuth();

  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  const location = useLocation();

  const handleDeshBoard = () => {
    if (
      location.pathname === "/seller-profile" ||
      location.pathname === "/buyer-transitions" ||
      location.pathname === "/partner-transitions"
    ) {
      // it means you are already in the dashboard
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You want to Go  Dashboard",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "No",
          cancelButtonText: "Yes",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            swalWithBootstrapButtons.fire({
              title: "Dashboard",
              text: "Redirecting to dashboard",
              icon: "success",
            });
            if (user.account_mode === "Seller") {
              // history.push("/seller-dashboard")
              window.location.href = "/seller-profile";
            } else if (user.account_mode === "Buyer") {
              window.location.href = "/buyer-transitions";
              //  history.push("/buyer-transitions")
            } else if (user.account_mode === "Partner") {
              //  history.push("/partner-transitions")
              window.location.href = "/partner-transitions";
            }
          }
        });
    }
  };

  const handleLogout = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to Log Out!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "No, Log Out",
        cancelButtonText: "Yes, Log Out!",
        reverseButtons: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Log Out!",
            text: "You Are Successfully Log Out",
            icon: "success",
          });
          signOutuser();

          localStorage.removeItem("token");
          localStorage.removeItem("user");

          window.location.href = "/home";
        }
      });
  };

  return (
    <header className="header-area-one">
      {/* ---------  Header-logo-area  -------------- */}
      <div className="header-logo-area  d-flex align-items-center">
        <div className="container">
          <div className="row d-flex flex-row">
            <div className="col-lg-4 col-md-12">
              <div
                className="site-branding"
                style={{ justifyContent: "center", alignItems: "center" }}>
                <a
                  href="/"
                  className="brand-logo"
                  style={{ width: "10.125em" }}>
                  <img
                    src={LogoImg2}
                    alt="logo"
                    style={{ width: "100%", height: "auto" }}
                    // style={{ width: "130px", height: "130px" }}
                  />
                </a>
                <a href="/" className="iframeanchor-tag">
                  <iframe
                    src="https://goldbroker.com/widget/live-price/XAU?currency=USD"
                    width="100%"
                    height="100"
                    title="iframe title"
                    className="iframe-custome"></iframe>
                </a>
              </div>
            </div>
            <div className="col-lg-8 col-md-9 d-xl-block d-none">
              <div className="site-info">
                <ul className="info-list">
                  {/* <li>
                    <div className="icon">
                      <a href="#">
                        <i className="fa-solid fa-mobile-screen-button"></i>
                      </a>
                    </div>
                    <div className="info">
                      <span className="title">{t("Phone Number")}</span>
                      <h5>
                        <a href="#">{contact_Number}</a>
                      </h5>
                    </div>
                  </li> */}
                  {/* <li>
                    <div className="icon">
                      <a href="#">
                        <i className="fa-regular fa-envelope"></i>
                      </a>
                    </div>
                    <div className="info">
                      <span className="title">{t("Email Address")}</span>
                      <h5>
                        <a href="#">office@eastgold.az</a>
                      </h5>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*------------- */}

      {/* ----------  Header Navigation -------------- */}
      <div className="container">
        <nav className="header-navigation navbar navbar-expand-lg d-flex align-items-center">
          <div className="row d-flex flex-row mx-0 px-0 align-items-center">
            <div className="col-lg-8 col-4 d-flex align-items-center">
              {/*---- OffCanvas ------ */}
              <button
                className="navbar-toggler d-flex align-items-center d-lg-none"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon">
                  <i className="fa-solid fa-bars"></i>
                </span>
              </button>

              <div
                className="offcanvas offcanvas-start"
                tabIndex="-1"
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header">
                  <h5
                    className="offcanvas-title"
                    id="offcanvasNavbarLabel"></h5>
                  <button
                    type="button"
                    className="close-button"
                    data-bs-dismiss="offcanvas">
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "36px",
                        padding: "5px 12px",
                        marginTop: "-20px",
                        marginRight: "-16px",
                      }}>
                      &times;
                    </p>
                  </button>
                </div>
                <div className="offcanvas-body">
                  <ul className="navbar-nav justify-content-start">
                    <li className="nav-item">
                      <Link className="nav-link active" to="/home">
                        {t("Home")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/about">
                        {t("About Us")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/products">
                        {t("Products")}
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link className="nav-link active" to="/hotel">
                        {t("Hotel")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link active" to="/tour">
                        {t("Tour")}
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link className="nav-link" to="/buyback">
                        {t("Buy Back")}
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link className="nav-link" to="/news">
                        {t("News")}
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      {/* <Link className="nav-link" to="/desgin">
                        {t("Create your gold bar")}
                      </Link> */}
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contact">
                        {t("Contact Us")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              {/*-------- */}
            </div>
            <div className="col-lg-4 col-8 d-flex align-items-center justify-content-end">
              {/*----- Header Right Nav ------ */}
              <ul className="d-flex align-items-center justify-content-end">
                <li className="d-xl-block d-none nav-item mt-2">
                  {/* <a
                    href="/contact"
                    // href={`tel: ${contact_Number}`}
                    className="main-btn float-right m-0">
                    {t("Send request")}
                  </a> */}
                  <Link className="main-btn float-right m-0" to="/contact">
                    {t("Send request")}
                  </Link>
                </li>
                {/* <li>
                  <form action="" id="userLangForms">
                    <select
                      name="code"
                      id="lang-code"
                      className="nav-item form-control from-control-sm "
                    >
                      <option value="en">
                        English
                      </option>
                      <option value="ar">عربي</option>
                    </select>
                  </form>
                </li> */}
              </ul>
              <div className="lang-icon">
                <Translation>{(t) => <ChangeLang t={t} />}</Translation>
              </div>

              {(() => {
                if (token) {
                  return (
                    <div
                      style={{ marginLeft: "20px" }}
                      onClick={handleDeshBoard}>
                      {t("Deshbord")}
                    </div>
                  );
                }
              })()}
              <div className="nav-item info nav-push-item">
                {(() => {
                  if (!token) {
                    return <Link to="/login">{t("Login")}</Link>;
                  } else {
                    return (
                      <div
                        style={{ marginLeft: "20px" }}
                        onClick={handleLogout}>
                        {t("Logout")}
                      </div>
                    );
                  }
                })()}

                {/* <Link to="/signup">Sign up</Link> */}
              </div>
              {/*-------- */}
            </div>
          </div>
        </nav>
      </div>
      {/* ------------- */}
    </header>
  );
}
