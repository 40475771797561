const filterWithLanguage = (langugaeCode, langugageFile) => {
  const result = {};

  langugaeCode.forEach((lang) => {
    result[lang] = {};
    langugageFile.forEach((item) => {
      const key = Object.keys(item)[0];
      result[lang][key] = item[key][lang];
    });
  });

  return { result };
};

export default async function GetTransation() {
  const langugae = await fetch(
    `https://eastgold.az/admin/api/language_country`
  );
  const res = await langugae.json();

  const generatecountry = res?.language_country?.map(
    (item) => item.language_code
  );

  const gettranslation = await fetch(
    `https://eastgold.az/admin/api/language_translation`
  );

  const gettranslationjson = await gettranslation.json();

  const langugageTranslationResult =
    gettranslationjson?.language_translation?.map((item) => {
      const { key, text } = item || {};

      return { [key]: JSON.parse(text) };
    });

  const result = filterWithLanguage(
    generatecountry,
    langugageTranslationResult
  );

  const { az, en, ru } = result?.result || {};

  const languages = {
    en: {
      translation: {
        ...en,
      },
    },
    az: {
      translation: {
        ...az,
      },
    },
    ru: {
      translation: {
        ...ru,
      },
    },
  };

  return languages;
}
