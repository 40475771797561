import React, { useEffect, useRef, useState } from "react";

import {
  HiOutlineMagnifyingGlassMinus,
  HiOutlineMagnifyingGlassPlus,
} from "react-icons/hi2";

import AvatarEditor from "react-avatar-editor";
import cardImage from "./../../assets/EG_background.png";
import backLogo from "./../../assets/img/cardLogo.png";
import LogoImg1 from "./../../assets/card/fordark.png";
import LogoImg2 from "./../../assets/card/forwhite.png";

import "./DesgingGoldbar.css";
import { ImageSetter } from "./ImageSetter";

const DesginLeftImage = ({
  zoomedImage,
  files,
  setZoomedImage,
  selectedQunatities,
  setselectedQunatities,
}) => {
  const [editor, setEditor] = useState(null);

  const [scale, setScale] = useState(1);
  const [logoChange, setLogoChange] = useState(true);

  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);

  // this code is for when it will automatically update in first render
  useEffect(() => {
    let flag = false;

    if (selectedQunatities !== "" && selectedQunatities) {
      flag = true;
    }

    if (flag) {
      if (imageUploadSuccess === true) {
        if (editor) {
          handlerUpdateImage(editor);
        }
      }
    }

    return () => {};
  }, [selectedQunatities, imageUploadSuccess]);

  const handlerUpdateImage = async (editor) => {
    try {
      const x = 0.42;
      const y = 0.4;
      const w = 0.15;
      const h = 0.18;

      await ImageSetter(
        editor,
        logoChange,
        LogoImg1,
        LogoImg2,
        backLogo,
        x,
        y,
        w,
        h
      ).then((result) => {
        setZoomedImage(result);
      });
    } catch (err) {
      throw new Error("Something went wrong");
    }
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
    setZoomedImage(null);
    setselectedQunatities("");
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1);
    setZoomedImage(null);
    setselectedQunatities("");
  };

  const Controls = () => {
    return (
      <>
        <HiOutlineMagnifyingGlassPlus
          className="text-white"
          onClick={handleZoomIn}
        />
        <HiOutlineMagnifyingGlassMinus
          className="text-white"
          onClick={handleZoomOut}
        />
      </>
    );
  };
  // height: 339.68,
  //   width: 220,
  return (
    <div className="position-relative text-center">
      <div className="d-flex justify-content-around mb-2">
        <Controls />
      </div>
      <div className="position-relative">
        {files.length === 0 && (
          <>
            <img
              src={cardImage}
              className="img-fluid"
              alt="choosen GOLD bar design"
            />
          </>
        )}

        {files.length > 0 && (
          <AvatarEditor
            // ref={(editor) => setEditor(editor)}
            ref={(editor) => setEditor(editor)}
            image={files.length > 0 && files[1] ? files[1].src : null}
            width={220}
            height={339}
            border={0}
            borderRadius={1}
            color={[11, 18, 33, 0.6]}
            rotate={0}
            className="react-transform-wrapper"
            style={{ marginTop: "10px" }}
            scale={scale}
            onPositionChange={() => {
              setZoomedImage(null);
              setselectedQunatities("");
            }}
            onLoadSuccess={(res) => {
              if (res.resource.currentSrc) {
                setImageUploadSuccess(true);
              }
            }}
          />
        )}
        <div className="position-absolute w-100 z-3 top-50 end-0 translate-middle-y d-flex justify-content-center">
          <img
            style={{ width: "38px", height: "70px" }}
            src={backLogo}
            className="mb-2 leftmiddlelogo"
            alt="choosen GOLD bar desgin"
          />
        </div>

        <div className="position-absolute bottom-0 start-50  translate-middle w-100 z-3 d-flex justify-content-center">
          <img
            src={logoChange ? LogoImg2 : LogoImg1}
            className="bottomlogocss"
            // style={{ width: "26%", marginBottom: "0" }}
            alt="choosen GOLD bar desgin"
          />
        </div>
      </div>
      <div>
        <img
          className={`p-2 ${logoChange ? "rounded border border-2" : ""}`}
          style={{ width: "50px" }}
          src={LogoImg2}
          alt="logo"
          onClick={() => {
            setLogoChange(true);
            setZoomedImage(null);
            setselectedQunatities("");
          }}
        />

        <img
          className={`ms-2 p-2 ${!logoChange ? "rounded border border-2" : ""}`}
          style={{ width: "50px" }}
          src={LogoImg1}
          alt="logo"
          onClick={() => {
            setLogoChange(false);
            setZoomedImage(null);
            setselectedQunatities("");
          }}
        />
      </div>

      {/* {zoomedImage && <img src={zoomedImage} alt="*" />} */}
    </div>
  );
};

export default DesginLeftImage;
