import "./Button.css";

const Button = ({ children, ...rest }) => {
  return (
    <button className="bottone1" {...rest}>
      <strong> {children}</strong>
    </button>
  );
};

export default Button;
