import React from "react";
import "./GoldBarChart.css";

const GoldBarChartSection = () => {
  return (
    <section className="features-area features-area-one">
      <div className="container-fluid ">
        <div className="goldbar-sction">
          {
            //  style={{ width: "90%", margin: "10px auto" }}
          }
          <iframe
            src="https://goldbroker.com/widget/historical/XAU?height=500&currency=USD"
            frameborder="0"
            width="100%"
            height="500"
            style={{ border: "0", overflow: "hidden" }}
            title="chart frame"
            allow-scripts
            allow-same-origin
            allow-popups></iframe>
        </div>
      </div>
    </section>
  );
};

export default GoldBarChartSection;
