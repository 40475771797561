import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Feature.css";
import ItemCard from "./ItemCard";
import ItemCardNews from "./ItemCardNews";
import { useTranslation } from "react-i18next";

// this is for carousel
const options = {
  drag: "true",
  mouseDrag: true,
  // loop: true,
  center: false,
  items: 4,
  margin: 10,
  autoplay: false,
  dots: true,
  autoplayTimeout: 8500,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 3,
    },
    992: {
      items: 3,
    },
  },
};

const FeatureItems = ({ data: featureItem = [], FeatureText, Content }) => {
  const { t, i18n } = useTranslation();
  // render all the item
  const renderFeatureItems = featureItem.map((item) => {
    const { id } = item || {};

    if (Content === "News") {
      return <ItemCardNews key={id} data={item} />;
    }
    return <ItemCard key={id} data={item} />;
  });

  return (
    <div
      style={{ marginTop: "50px" }}
      className="feature-hotel section-header-part breadcrumb-section">
      <div className="section-title section-title-left mb-40">
        <span className="sub-title">{t(`${FeatureText}`)}</span>
      </div>
      <div className="row">
        <OwlCarousel className="owl-theme" {...options}>
          {renderFeatureItems}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default FeatureItems;
