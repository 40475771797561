import React, { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import backLogo from "./../../assets/img/backLogo.png";

import { HiOutlineMagnifyingGlassPlus } from "react-icons/hi2";
import { HiOutlineMagnifyingGlassMinus } from "react-icons/hi2";
import cardImage from "./../../assets/EG_background.png";

import LogoImg1 from "./../../assets/card/fordark.png";
import LogoImg2 from "./../../assets/card/forwhite.png";
import { ImageSetter } from "./ImageSetter";

const DesingRightImage = ({
  files,
  rightSidezoomedImage,
  setRightSideZoomedImage = () => {},
  setselectedQunatities = () => {},
  selectedQunatities,
}) => {
  const [editor, setEditor] = useState(null);
  // const editorRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [logoChange, setLogoChange] = useState(true);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);

  useEffect(() => {
    let flag = false;

    if (selectedQunatities !== "" && selectedQunatities) {
      flag = true;
    }

    if (flag) {
      if (imageUploadSuccess === true) {
        if (editor) {
          handlerUpdateImage(editor);
        }
      }
    }

    return () => {};
  }, [selectedQunatities, imageUploadSuccess]);

  const handlerUpdateImage = async (editor) => {
    try {
      const x = 0.36;
      const y = 0.74;
      const w = 0.26;
      const h = 0.12;

      await ImageSetter(
        editor,
        logoChange,
        LogoImg1,
        LogoImg2,
        backLogo,
        x,
        y,
        w,
        h
      ).then((result) => {
        setRightSideZoomedImage(result);
      });
    } catch (err) {
      throw new Error("Something went wrong");
    }
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1); // Increase scale by 0.1
    setRightSideZoomedImage(null);
    setselectedQunatities("");
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1); // Decrease scale by 0.1
    setRightSideZoomedImage(null);
    setselectedQunatities("");
  };

  const Controls = () => {
    return (
      <>
        <HiOutlineMagnifyingGlassPlus
          className="text-white"
          onClick={() => handleZoomIn()}
        />
        <HiOutlineMagnifyingGlassMinus
          className="text-white"
          onClick={() => handleZoomOut()}
        />
      </>
    );
  };

  return (
    <div className="position-relative text-center">
      <div className="d-flex justify-content-around mb-2">
        <Controls />
      </div>
      <div className="position-relative">
        {files.length === 0 && (
          <>
            <img
              src={cardImage}
              className="position-relative img-fluid z-1 top-0 left-0 w-100"
              alt="choosen GOLD bar design"
            />
          </>
        )}

        {files.length > 0 && (
          <AvatarEditor
            // ref={(editor) => setEditor(editor)}
            ref={(editor) => setEditor(editor)}
            image={files[1].src}
            width={220}
            height={339}
            border={0}
            borderRadius={1}
            color={[11, 18, 33, 0.6]} // RGBA
            rotate={0}
            className="react-transform-wrapper"
            style={{ marginTop: "10px" }}
            scale={scale}
            onPositionChange={() => {
              setRightSideZoomedImage(null);
              setselectedQunatities("");
            }}
            onLoadSuccess={(res) => {
              if (res.resource.currentSrc) {
                setImageUploadSuccess(true);
              }
            }}
          />
        )}

        <div className="rightDivGold">
          <img
            style={{ width: "75px", height: "40px" }}
            src={backLogo}
            className="downlogo"
            alt="choosen GOLD bar desgin"
          />
        </div>

        <div className="position-absolute bottom-0 start-50  translate-middle w-100 z-3 d-flex justify-content-center">
          <img
            src={logoChange ? LogoImg2 : LogoImg1}
            style={{ width: "70px" }}
            className="rightDivGoldLogo"
            alt="choosen GOLD bar desgin"
          />
        </div>
      </div>
      <div>
        <img
          className={`p-2 ${logoChange ? "rounded border border-2" : ""}`}
          style={{ width: "50px" }}
          src={LogoImg2}
          alt="logo"
          onClick={() => {
            setLogoChange(true);
            setRightSideZoomedImage(null);
            setselectedQunatities("");
          }}
        />

        <img
          className={`ms-2 p-2 ${!logoChange ? "rounded border border-2" : ""}`}
          style={{ width: "50px" }}
          src={LogoImg1}
          alt="logo"
          onClick={() => {
            setLogoChange(false);
            setRightSideZoomedImage(null);
            setselectedQunatities("");
          }}
        />
      </div>

      {/* {rightSidezoomedImage && <img src={rightSidezoomedImage} alt="mm" />} */}
    </div>
  );
};

export default DesingRightImage;
