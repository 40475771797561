import React from "react";
import "./PreLoaderCard.css";

const SingleContent = () => {
  const preloadContent = Array.from(Array(4), (x, i) => {
    return (
      <div
        key={i}
        style={{ marginRight: "20px" }}
        className="card_load_extreme_descripion"></div>
    );
  });

  return (
    <div
      style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
      {preloadContent}
    </div>
  );
};

export default SingleContent;
