import React from "react";
import "./Feature.css";
import { Translation } from "react-i18next";
import { useTranslation } from "react-i18next";
import imgOne from "./../../../assets/est1-removebg-preview.png";
import imgTwo from "./../../../assets/est2-removebg-preview.png";
import imgThree from "./../../../assets/est3-removebg-preview.png";
import imgFour from "./../../../assets/est4-removebg-preview.png";
import backgroundIMG from "./Featureimg/background.jpg";
import featureImage from "./../../../assets/feature.jpeg";

export default function FeatureSection() {
  const { t, i18n } = useTranslation();

  const externalImage = featureImage;
  //  "https://i.ibb.co/v3S4NXV/background.jpg";
  return (
    <>
      <section className="features-area features-area-one">
        <div className="container-fluid ">
          <div className="row features-wrapper-one no-gutters ">
            <div className="col-lg-3 features-column">
              <div className="features-item features-item-one text-center">
                <div
                  className="item-bg bg_cover lazy entered loaded"
                  data-bg={externalImage}
                  data-ll-status="loaded"
                  style={{ backgroundImage: `url(${externalImage})` }}></div>

                <div className="icon">
                  <img src={imgOne} alt="feature" style={{ width: "90px" }} />
                  {/* <i className="fab fa-accusoft"></i> */}
                </div>

                <div className="content">
                  <h4>{t("Rental services")}</h4>
                  <p>
                    {t(
                      "Description: Rental points and stores for Jewelry selling"
                    )}
                  </p>

                  <a href="#" className="icon-btn">
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 features-column">
              <div className="features-item features-item-one text-center">
                <div
                  className="item-bg bg_cover lazy entered error"
                  data-bg={externalImage}
                  data-ll-status="error"
                  style={{ backgroundImage: `url(${externalImage})` }}></div>
                {/* <div className="icon">
                  <i className="fas fa-arrows-alt"></i>
                </div> */}
                <div className="icon">
                  <img src={imgTwo} alt="feature" style={{ width: "90px" }} />
                </div>
                <div className="content">
                  <h4>{t("Financial services")}</h4>
                  <p>
                    {t(
                      "Tax reports, Financial consulting services for small jewelry producers and sellers"
                    )}
                  </p>
                  <a href="#" className="icon-btn">
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 features-column">
              <div className="features-item features-item-one text-center">
                <div
                  className="item-bg bg_cover lazy entered error"
                  data-bg={externalImage}
                  data-ll-status="error"
                  style={{ backgroundImage: `url(${externalImage})` }}></div>
                {/* <div className="icon">
                  <i className="fas fa-address-card"></i>
                </div> */}
                <div className="icon">
                  <img src={imgThree} alt="feature" style={{ width: "90px" }} />
                </div>
                <div className="content">
                  <h4>{t("Marketing services")}</h4>
                  <p>
                    {t(
                      "Professional photography, videography, social media marketing services for jewelry manufacturers and sellers"
                    )}
                  </p>
                  <a href="#" className="icon-btn">
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 features-column">
              <div className="features-item features-item-one text-center">
                <div
                  className="item-bg bg_cover lazy entered error"
                  data-bg={externalImage}
                  data-ll-status="error"
                  style={{ backgroundImage: `url(${externalImage})` }}></div>
                {/* <div className="icon">
                  <i className="fab fa-accusoft"></i>
                </div> */}
                <div className="icon">
                  <img src={imgOne} alt="feature" style={{ width: "90px" }} />
                </div>
                <div className="content">
                  <h4>{t("Event Management")}</h4>
                  <p>
                    {t(
                      "Organization of events and auctions to stimulate the sale of gold jewelry"
                    )}
                  </p>
                  <a href="#" className="icon-btn">
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 features-column">
              <div className="features-item features-item-one text-center">
                <div
                  className="item-bg bg_cover lazy entered error"
                  data-bg={externalImage}
                  data-ll-status="error"
                  style={{ backgroundImage: `url(${externalImage})` }}></div>
                <div className="icon">
                  <i className="fas fa-bell-slash"></i>
                </div>
                <div className="icon">
                  {/* <img src={imgFour} alt="feature" style={{ width: "90px" }} /> */}
                </div>
                <div className="content">
                  <h4 className="contentInfo">{t("Product developement")}</h4>
                  <p>
                    {t(
                      "Stimulating sales leads to new loyalty, credit, etc. creation and development of products"
                    )}
                  </p>
                  <a href="#" className="icon-btn">
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
