import { useEffect, useState } from "react";

function useGetEffectItems(urlParams) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setLoading(false);
    fetch(`${process.env.REACT_APP_Url}/${urlParams}`, { signal })
      .then((response) => response.json())
      .then((data) => {
        const getDataValues = Object.values(data)[0];

        const dataResponse = getDataValues?.map((item) => {
          return {
            ...item,
            en_title: item.title || "",
            en_name: item.name || "",
            en_description: item.description || "",
            en_location: item.location || "",
            en_short_description: item.short_description || "",
            en_highlight: item.highlight || "",
          };
        })[0];

        setData(dataResponse);
        setLoading(true);
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          // The request was aborted, do nothing
          console.log("Request aborted", urlParams);
        } else {
          setError(error);
        }
        setLoading(true);
      });

    return () => {
      controller.abort();
    };
  }, [urlParams]);

  return { data, error, loading };
}

export default useGetEffectItems;
