import React from "react";
import { useTranslation } from "react-i18next";
import "./About.css";
import AboutUsImageOne from "./../../assets/shargbazar.jpg";
import AboutUsImageTwo from "./../../assets/whygoldsection.jpg";
import Whyinvestgoldbar from "../Whyinvestgoldbar";
import AboutUs from "../Home/AboutSection/AboutUs";

export default function Portfolios() {
  const { t, i18n } = useTranslation();

  const lang = i18n?.language || "en";

  const AboutUsTextRender =
    lang === "az" ? (
      <p>
        {t(
          "Each of our gifts is the capital for your family, value for your home, investment for your future!"
        )}
        <br />
        <br />
        <br />
        We invite you to join us
      </p>
    ) : (
      <p>
        Each of our gifts is the capital for your family, value for your home,
        investment for your future!
        <br />
        <br />
        <br />
        {t("We invite you to join us")}
      </p>
    );

  return (
    <>
      {/* ---------- Breadcrumb Section -------- */}
      <section
        className="section-header-part breadcrumb-section bg-img-c lazy entered loaded"
        data-ll-status="loaded">
        <div className="container"></div>
      </section>

      {/* ----------  Project Section -------- */}
      <section className="about-area pt-5 pb-70" id="about">
        <div className="container">
          <AboutUs />

          {/* ---------- devider Section -------- */}
          <div className="image-containerbox">
            <img src={AboutUsImageTwo} alt="Your  Description" />
          </div>
          {/* this is Why should you invest in gold bar? part start*/}
          <Whyinvestgoldbar />
          {/* this is Why should you invest in gold bar? part end*/}
        </div>
      </section>
    </>
  );
}
