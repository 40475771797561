import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import i18n from "./app/Components/LangConfig";
import i18n from "./components/LangConfig";
import { BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";

function AppRender() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("EastGold.Az - Gold bazar of East")}</title>
        <meta
          name="description"
          content={t(
            "EastGold jewellers offers best quality diamond and gold jewellery with wide varieties of collections. Buy well designed wedding jewellery within affordable prices"
          )}
        />
        <meta
          name="keywords"
          content={t(
            "Gold, Azerbaijan, gold bars, PAMP, gram gold, gram bar, gift gold bar, jewelry, gold market, 999 carat, 585 carat, 750 carat, wedding sets, diamond, natural rocks, Gold Rings, Gold Earrings, Gold Bracelets, Gold Pendants, Chains, Necklaces, Bangles, Anklets"
          )}
        />
      </Helmet>

      <BrowserRouter>
        <React.Suspense fallback="loading..">
          <App />
        </React.Suspense>
      </BrowserRouter>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppRender />);

reportWebVitals();
