import React from "react";
import AboutUsImageOne from "../../../assets/shargbazar.jpg";
import { useTranslation } from "react-i18next";
import "./About.css";

const AboutUs = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="row pb-30">
      <div className="col-lg-6">
        <div
          className="about-content-box about-content-box-one mb-50 wow fadeInLeft"
          data-wow-delay=".3s"
          style={{ visibility: "visible", animationDelay: "0.3s" }}>
          <div className="section-title section-title-left mb-40">
            <span className="sub-title">{t("About Us")}</span>
            <h2 className="">
              {/* {t(`Professional Business Guidance Agency`)} */}
            </h2>
          </div>
          <p className="mb-4" style={{ textAlign: "justify", color: "white" }}>
            {" "}
            {t(
              `Being the operator of the “Oriental Bazar” established in 1982 and reconstructed again in 2023 and known as the largest gold market in Azerbaijan “East Gold” LLC. positions itself with its unique role in the segment of jewelry, especially gold bullions.Providing the sales and buy back of the gold bullions for its numerous and valuable customers “East Gold” LLC, is proud to present you its product range, especcialy the gold bullion gifts, which is memorable and ever-increasing value.East Gold LLC is the company that makes gold bullions, which are always increasing in price, create a feeling of confidence, and is considered an investment, so accessible. We are proud of your satisfaction and positive feedback.`
            )}
            <br />
            <br />
            {t(
              `Each of our gifts is the capital for your family, value for your home, investment for your future!`
            )}
            <br />
            <br />
            {t(
              `We invite you to join us and invest in your children and loved ones!`
            )}
          </p>
          {/* <a href="http://example.com/" className="main-btn arrow-btn">
            {t(`LEARN MORE`)}
            <i className="fas fa-arrow-right"></i>
          </a> */}
        </div>
      </div>

      <div className="col-lg-6">
        <div
          className="about-img-one wow fadeInUp d-none d-md-none d-lg-block"
          data-wow-delay=".45s"
          style={{
            visibility: "visible",
            animationDelay: "0.45s",
            height: "600px",
          }}>
          <img
            className="lazy entered loaded"
            data-src={AboutUsImageOne}
            alt="about"
            data-ll-status="loaded"
            src={AboutUsImageOne}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
