import { Button } from "@material-ui/core";
import React, { useState } from "react";
import "./Hotel.css";
import { useTranslation } from "react-i18next";

const LuxiousHotel = ({ data: featureItem = [], FeatureText }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n?.language || "en";

  const [visible, setVisible] = useState(4);

  const loadMore = () => {
    setVisible((prev) => parseInt(prev) + 2);
  };

  const LuxiousHotelRender = featureItem?.slice(0, visible).map((item) => {
    const { id, images } = item || {};

    const name = item[`${lang}_name`];
    const description = item[`${lang}_short_description`];

    return (
      <div key={id} className="col-12 col-md-6 box-feature">
        <div className="d-flex align-items-cente hotel-box-size">
          <img
            src="https://images.pexels.com/photos/2265876/pexels-photo-2265876.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            // src={`${process.env.REACT_APP_IMAGE_URL}` + images[0].image}
            alt="hotel"
            className="w-25"
            style={{ objectFit: "contain" }}
          />
          <div className="ml-2 ml-md-4 d-flex flex-column justify-content-center">
            <h1>{name}</h1>
            <p
              className="mb-1 mb-md-4"
              style={{ textAlign: "justify", color: "white" }}
              dangerouslySetInnerHTML={{ __html: description }}></p>
            <a href="http://example.com/" className="feature-btn arrow-btn">
              LEARN MORE
              <i className="fas fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div
      style={{ marginTop: "30px" }}
      className="feature-hotel section-header-part breadcrumb-section">
      <div className="container">
        <div className="section-title section-title-left mb-40">
          <span className="sub-title">{FeatureText}</span>
        </div>
        <div className="row row-gap-4 col-gap-4 luxurious-hotel">
          {LuxiousHotelRender}
        </div>
        <div className="mt-5 text-center">
          {visible < featureItem?.length && (
            <Button
              onClick={loadMore}
              className="px-3 py-3 text-white border border-light">
              See More Hotel
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LuxiousHotel;
