import React from "react";
import CarouselImg1 from "../../assets/banner_home_eastgold_one.jpg";
import CarouselImg2 from "../../assets/banner_home_eastgold_two.jpg";
import "./Home.css";
// import { Translation } from "react-i18next";
import { useTranslation } from "react-i18next";
// import {  } from "react-router-dom";

export default function HeroSection() {
  const { t, i18n } = useTranslation();
  const [slider, setSlider] = React.useState([]);

  React.useEffect(() => {
    GetSliderItem();
  }, []);

  const GetSliderItem = async () => {
    try {
      const sliderUrl = await fetch(`${process.env.REACT_APP_Url}/sliders`);
      const sliderResponse = await sliderUrl.json();

      setSlider(sliderResponse.data.sliders);
    } catch (e) {
      console.log("error happend in slider response", e);
    }
  };

  const handler = (link) => {
    window.location.href = link;
  };

  const getSliderItemRender = slider?.map(({ slider_image, link }, index) => {
    const activeClass = index === 0 ? "active" : "";

    return (
      <div
        key={index}
        className={`carousel-item carousel-card ${activeClass}`}
        data-bs-interval="5000"
        onClick={() => handler(link)}>
        <img src={slider_image} className="d-block w-100" alt="slider-1" />
      </div>
    );
  });

  if (slider?.length === 0) return <></>;

  return (
    <>
      {/* <!--====== Start Hero section ======--> */}
      <section className="hero-area">
        <div
          className="hero-wrapper-one carousel slide carousel-fade"
          id="carouselExampleInterval"
          data-bs-ride="carousel">
          <div className="hero-slider-one">
            <div className="single-slider">
              <div className="carousel-inner bg_cover lazy">
                {getSliderItemRender}
              </div>

              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleInterval"
                data-bs-slide="prev">
                <i className="fa-solid fa-arrow-left"></i>
                <span className="visually-hidden">{t("Previous")}</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleInterval"
                data-bs-slide="next">
                <i className="fa-solid fa-arrow-right"></i>
                <span className="visually-hidden">{t("Next")}</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* <!--====== End Hero section ======--> */}
    </>
  );
}
