import "./Index.css";

export const ModalDialog = ({
  setOpen = () => {},
  setInputNumber = () => {},
  handlerClick = () => {},
  setErrorAmount = () => {},
  buttonText = "",
  error = "",
}) => {
  return (
    <div className="modal--div">
      <div className="cookiesContent" id="cookiesPopup">
        <button
          className="close"
          onClick={() => {
            setOpen(false);
          }}>
          ✖
        </button>

        <input
          onChange={(e) => {
            setInputNumber(e.target.value);
            setErrorAmount("");
          }}
          className="effect-9"
          type="number"
          placeholder="Give A Amount"
        />
        <p style={{ marginBottom: "-1px" }}>{error}</p>
        <button
          className="accept"
          style={{ marginTop: "13px" }}
          onClick={handlerClick}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};
