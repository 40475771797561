import React from "react";

const SearchInformation = ({ handler, filterText }) => {
  return (
    <form className="d-flex border mb-2" role="search">
      <input
        className="form-control text-white"
        type="search"
        placeholder="Search"
        aria-label="Search"
        style={{
          background: "transparent",
          border: "transparent",
        }}
        onChange={handler}
        value={filterText}
      />
      <div
        className="d-flex align-items-center"
        style={{
          background: "transparent",
        }}>
        <i className="pr-2 fa-solid fa-magnifying-glass text-white"></i>
      </div>
    </form>
  );
};

export default SearchInformation;
